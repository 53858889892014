// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiSVG from "lumi-rescript/lib/es6/src/LumiSVG.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as V2f$Linear from "lumi-linear/lib/es6/src/V2f.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as M23f$Linear from "lumi-linear/lib/es6/src/M23f.bs.mjs";
import * as M33f$Linear from "lumi-linear/lib/es6/src/M33f.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as CamlinternalLazy from "rescript/lib/es6/camlinternalLazy.js";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as BoundingBox$Linear from "lumi-linear/lib/es6/src/BoundingBox.bs.mjs";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";

function shapeVertices(shape) {
  switch (shape.TAG) {
    case "Rect" :
        var match = shape._0;
        var height = match[1];
        var width = match[0];
        return [
                [
                  0,
                  0
                ],
                [
                  width,
                  0
                ],
                [
                  width,
                  height
                ],
                [
                  0,
                  height
                ]
              ];
    case "Polygon" :
        return shape._0;
    case "Transformation" :
        var transform = (function (__x) {
              return function (extra) {
                return M33f$Linear.Mult.v3f(__x, extra);
              };
            })(M23f$Linear.toM33f(shape._0));
        return shapeVertices(shape._1).map(function (__x) {
                    return V2f$Linear.asV3f(__x, transform);
                  });
    
  }
}

function aabb(shape) {
  return BoundingBox$Linear.axisAlignedBoundingBox(shapeVertices(shape));
}

function decompose(_transforms, _shape) {
  while(true) {
    var shape = _shape;
    var transforms = _transforms;
    switch (shape.TAG) {
      case "Rect" :
      case "Polygon" :
          return [
                  transforms,
                  shape
                ];
      case "Transformation" :
          _shape = shape._1;
          _transforms = transforms.concat([shape._0]);
          continue ;
      
    }
  };
}

function toElement(shape, children) {
  switch (shape.TAG) {
    case "Rect" :
        var match = V2$Linear.map(shape._0, (function (prim) {
                return prim.toString();
              }));
        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx("rect", {
                            height: match[1],
                            width: match[0],
                            vectorEffect: "non-scaling-stroke"
                          }),
                      children
                    ]
                  });
    case "Polygon" :
        var points = shape._0.map(function (point) {
                var match = V2$Linear.map(point, (function (prim) {
                        return prim.toString();
                      }));
                return match[0] + "," + match[1];
              }).join(" ");
        return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                    children: [
                      JsxRuntime.jsx("polygon", {
                            points: points,
                            vectorEffect: "non-scaling-stroke"
                          }),
                      children
                    ]
                  });
    case "Transformation" :
        var transform = LumiSVG.Transform.Matrix.fromM23f(shape._0);
        return JsxRuntime.jsx("g", {
                    children: toElement(shape._1, children),
                    transform: transform
                  });
    
  }
}

function FacilityMap$Shape(props) {
  var __children = props.children;
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var className = LumiReact.classList(classList);
  return JsxRuntime.jsx("g", {
              children: toElement(props.shape, children),
              className: className,
              id: props.id,
              onClick: props.onClick
            });
}

var lazyTransformation = {
  LAZY_DONE: false,
  VAL: (function () {
      return LumiJzon.tuple2(M23f$Linear.jsonCodec, CamlinternalLazy.force(lazyShape));
    })
};

var lazyShape = {
  LAZY_DONE: false,
  VAL: (function () {
      return LumiJzon.taggedVariant((function (shape) {
                    switch (shape.TAG) {
                      case "Rect" :
                          return [
                                  "Rect",
                                  Jzon.encodeWith(shape._0, V2f$Linear.jsonCodec)
                                ];
                      case "Polygon" :
                          return [
                                  "Polygon",
                                  Jzon.encodeWith(shape._0, Jzon.array(V2f$Linear.jsonCodec))
                                ];
                      case "Transformation" :
                          return [
                                  "Transformation",
                                  Jzon.encodeWith([
                                        shape._0,
                                        shape._1
                                      ], CamlinternalLazy.force(lazyTransformation))
                                ];
                      
                    }
                  }), (function (param) {
                    var json = param[1];
                    var tag = param[0];
                    switch (tag) {
                      case "Polygon" :
                          return Core__Result.map(Jzon.decodeWith(json, Jzon.array(V2f$Linear.jsonCodec)), (function (ps) {
                                        return {
                                                TAG: "Polygon",
                                                _0: ps
                                              };
                                      }));
                      case "Rect" :
                          return Core__Result.map(Jzon.decodeWith(json, V2f$Linear.jsonCodec), (function (r) {
                                        return {
                                                TAG: "Rect",
                                                _0: r
                                              };
                                      }));
                      case "Transformation" :
                          return Core__Result.map(Jzon.decodeWith(json, CamlinternalLazy.force(lazyTransformation)), (function (param) {
                                        return {
                                                TAG: "Transformation",
                                                _0: param[0],
                                                _1: param[1]
                                              };
                                      }));
                      default:
                        return {
                                TAG: "Error",
                                _0: {
                                  NAME: "UnexpectedJsonValue",
                                  VAL: [
                                    [{
                                        TAG: "Field",
                                        _0: "tag"
                                      }],
                                    tag
                                  ]
                                }
                              };
                    }
                  }));
    })
};

var transformation = CamlinternalLazy.force(lazyTransformation);

var jsonCodec = CamlinternalLazy.force(lazyShape);

var Shape = {
  shapeVertices: shapeVertices,
  aabb: aabb,
  decompose: decompose,
  toElement: toElement,
  make: FacilityMap$Shape,
  lazyTransformation: lazyTransformation,
  lazyShape: lazyShape,
  transformation: transformation,
  jsonCodec: jsonCodec
};

var jsonCodec$1 = Jzon.object2((function (param) {
        return [
                param.sensor,
                param.shape
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  sensor: param[0],
                  shape: param[1]
                }
              };
      }), Jzon.field("sensor", Jzon.nullable(Document$LumiStore.Key.jsonCodec())), Jzon.field("shape", jsonCodec));

var Spot = {
  jsonCodec: jsonCodec$1
};

function jsonCodec$2() {
  return Jzon.object3((function (param) {
                return [
                        param.key,
                        param.position,
                        param.orientation
                      ];
              }), (function (param) {
                return {
                        TAG: "Ok",
                        _0: {
                          key: param[0],
                          position: param[1],
                          orientation: param[2]
                        }
                      };
              }), Jzon.field("key", Jzon.nullable(Document$LumiStore.Key.jsonCodec())), Jzon.field("position", V2f$Linear.jsonCodec), Jzon.field("orientation", Jzon.nullable(V2f$Linear.jsonCodec)));
}

var Device = {
  jsonCodec: jsonCodec$2
};

var jsonCodec$3 = Jzon.object2((function (param) {
        return [
                param.name,
                param.index
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  name: param[0],
                  index: param[1]
                }
              };
      }), Jzon.field("name", Jzon.string), Jzon.field("index", Jzon.$$int));

function sortKey(info) {
  return [
          info.index,
          info.name
        ];
}

var FacilityMapInfo = {
  jsonCodec: jsonCodec$3,
  sortKey: sortKey
};

var jsonCodec$4 = Jzon.object6((function (param) {
        return [
                param.background,
                param.spots,
                param.cameras,
                param.indicators,
                param.displays,
                param.name
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  background: param[0],
                  spots: param[1],
                  cameras: param[2],
                  indicators: param[3],
                  displays: param[4],
                  name: param[5]
                }
              };
      }), Jzon.field("background", jsonCodec), Jzon.field("spots", LumiJzon.mapString(jsonCodec$1)), Jzon.field("cameras", LumiJzon.mapString(jsonCodec$2())), Jzon.field("indicators", LumiJzon.mapString(jsonCodec$2())), Jzon.field("displays", LumiJzon.mapString(jsonCodec$2())), Jzon.field("name", Jzon.string));

var FacilityMap = {
  jsonCodec: jsonCodec$4
};

function documentName(param) {
  return param.name;
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "map-location"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "facility-map",
      jsonCodec: jsonCodec$4,
      icon: icon
    });

export {
  Shape ,
  Spot ,
  Device ,
  FacilityMapInfo ,
  FacilityMap ,
  Schema ,
}
/* transformation Not a pure module */
