// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";

var jsonCodec = Jzon.object3((function (param) {
        return [
                param.forEmailAddress,
                param.identityProviderName,
                param.samlRequestUrl
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  forEmailAddress: param[0],
                  identityProviderName: param[1],
                  samlRequestUrl: param[2]
                }
              };
      }), Jzon.field("forEmailAddress", Jzon.string), Jzon.optional(Jzon.field("identityProviderName", Jzon.string)), Jzon.optional(Jzon.field("samlRequestUrl", Jzon.string)));

var RequestData = {
  jsonCodec: jsonCodec
};

export {
  RequestData ,
}
/* jsonCodec Not a pure module */
