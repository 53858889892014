// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as Schema$LumiStore from "lumi-store/lib/es6/src/Schema.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Types$LumiDocuments from "./Types.bs.mjs";
import * as Timelines$LumiDocuments from "./Types/Timelines.bs.mjs";
import * as SensorGroups$LumiStaticData from "lumi-static-data/lib/es6/src/SensorGroups.bs.mjs";
import * as FacilityServer$LumiDocuments from "./FacilityServer.bs.mjs";

var jsonCodec = Jzon.custom((function (v) {
        var tmp;
        switch (v) {
          case "Planned" :
              tmp = "Planned";
              break;
          case "InitialTrainingPeriod" :
              tmp = "InitialTrainingPeriod";
              break;
          case "Operational" :
              tmp = "Operational";
              break;
          case "Archived" :
              tmp = "Archived";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (v) {
                      switch (v) {
                        case "Archived" :
                            return {
                                    TAG: "Ok",
                                    _0: "Archived"
                                  };
                        case "InitialTrainingPeriod" :
                            return {
                                    TAG: "Ok",
                                    _0: "InitialTrainingPeriod"
                                  };
                        case "Operational" :
                            return {
                                    TAG: "Ok",
                                    _0: "Operational"
                                  };
                        case "Planned" :
                            return {
                                    TAG: "Ok",
                                    _0: "Planned"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      v
                                    ]
                                  }
                                };
                      }
                    }));
      }));

function toString(value) {
  switch (value) {
    case "Planned" :
        return "Planned";
    case "InitialTrainingPeriod" :
        return "Initial training period";
    case "Operational" :
        return "Operational";
    case "Archived" :
        return "Archived";
    
  }
}

function Facility$FacilityOperationalState(props) {
  var value = props.value;
  var tmp;
  switch (value) {
    case "Planned" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "person-digging"
                  }),
              className: "icon has-text-warning"
            });
        break;
    case "InitialTrainingPeriod" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "wand-magic-sparkles"
                  }),
              className: "icon has-text-success"
            });
        break;
    case "Operational" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "gears"
                  }),
              className: "icon has-text-success"
            });
        break;
    case "Archived" :
        tmp = JsxRuntime.jsx("span", {
              children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                    name: "broom"
                  }),
              className: "icon has-text-grey-light"
            });
        break;
    
  }
  return JsxRuntime.jsxs("span", {
              children: [
                tmp,
                JsxRuntime.jsx("span", {
                      children: toString(value)
                    })
              ],
              className: "icon-text"
            });
}

var FacilityOperationalState = {
  jsonCodec: jsonCodec,
  toString: toString,
  make: Facility$FacilityOperationalState
};

var jsonCodec$1 = Jzon.object2((function (param) {
        return [
                param.description,
                param.identifier
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  description: param[0],
                  identifier: param[1]
                }
              };
      }), Jzon.field("description", Jzon.string), Jzon.field("identifier", Jzon.string));

var NprOpenData = {
  jsonCodec: jsonCodec$1
};

var jsonCodec$2 = Jzon.object5((function (param) {
        return [
                param.identifier,
                param.upperGroup,
                param.lowerGroup,
                param.chargePointGroup,
                param.enableUpload
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  identifier: param[0],
                  upperGroup: param[1],
                  lowerGroup: param[2],
                  chargePointGroup: param[3],
                  enableUpload: param[4]
                }
              };
      }), Jzon.field("identifier", Jzon.string), Jzon.field("upperGroup", SensorGroups$LumiStaticData.GroupFullId.jsonCodec), Jzon.field("lowerGroup", SensorGroups$LumiStaticData.GroupFullId.jsonCodec), Jzon.field("chargePointGroup", Jzon.nullable(SensorGroups$LumiStaticData.GroupFullId.jsonCodec)), Jzon.field("enableUpload", Jzon.bool));

var HbfUploadSpec = {
  jsonCodec: jsonCodec$2
};

var jsonCodec$3 = LumiJzon.taggedVariant((function (source) {
        if (source.TAG === "ProRail") {
          return [
                  "ProRail",
                  Jzon.encodeWith(source._0, Jzon.string)
                ];
        } else {
          return [
                  "Abel",
                  Jzon.encodeWith(source._0, Jzon.string)
                ];
        }
      }), (function (param) {
        var json = param[1];
        var tag = param[0];
        switch (tag) {
          case "Abel" :
              return Core__Result.map(Jzon.decodeWith(json, Jzon.string), (function (uuid) {
                            return {
                                    TAG: "Abel",
                                    _0: uuid
                                  };
                          }));
          case "ProRail" :
              return Core__Result.map(Jzon.decodeWith(json, Jzon.string), (function (uuid) {
                            return {
                                    TAG: "ProRail",
                                    _0: uuid
                                  };
                          }));
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

var DownloadSource = {
  jsonCodec: jsonCodec$3
};

var jsonCodec$4 = Jzon.object5((function (param) {
        return [
                param.source,
                param.group,
                param.sensorLow,
                param.sensorHigh,
                param.enableDownload
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  source: param[0],
                  group: param[1],
                  sensorLow: param[2],
                  sensorHigh: param[3],
                  enableDownload: param[4]
                }
              };
      }), Jzon.field("source", jsonCodec$3), Jzon.field("group", SensorGroups$LumiStaticData.GroupFullId.jsonCodec), Jzon.field("sensorLow", Document$LumiStore.Key.jsonCodec()), Jzon.field("sensorHigh", Document$LumiStore.Key.jsonCodec()), Jzon.field("enableDownload", Jzon.bool));

var DownloadSpec = {
  jsonCodec: jsonCodec$4
};

var jsonCodec$5 = Jzon.object14((function (param) {
        return [
                param.name,
                param.position,
                param.address,
                param.group,
                param.extraStatsGroups,
                param.servers,
                param.operationalState,
                param.openingTimes,
                param.inExternalApi,
                param.nprOpenData,
                param.hbfUploadSpec,
                param.downloadSpec,
                param.defaultMap,
                param.maps
              ];
      }), (function (param) {
        return {
                TAG: "Ok",
                _0: {
                  name: param[0],
                  position: param[1],
                  address: param[2],
                  group: param[3],
                  extraStatsGroups: param[4],
                  servers: param[5],
                  operationalState: param[6],
                  openingTimes: param[7],
                  inExternalApi: param[8],
                  nprOpenData: param[9],
                  hbfUploadSpec: param[10],
                  downloadSpec: param[11],
                  defaultMap: param[12],
                  maps: param[13]
                }
              };
      }), Jzon.field("name", Jzon.string), Jzon.field("position", Types$LumiDocuments.GeoPosition.jsonCodec), Jzon.field("address", Jzon.string), Jzon.field("group", Jzon.nullable(SensorGroups$LumiStaticData.GroupFullId.jsonCodec)), Jzon.field("extraStatsGroups", Jzon.array(SensorGroups$LumiStaticData.GroupFullId.jsonCodec)), Jzon.field("servers", LumiJzon.set(Document$LumiStore.Key.jsonCodec(), FacilityServer$LumiDocuments.Schema.CmpKey)), Jzon.field("operationalState", jsonCodec), Jzon.field("openingTimes", Timelines$LumiDocuments.Timeline.jsonCodec(Jzon.string, Types$LumiDocuments.OpenClosedState.jsonCodec)), Jzon.field("inExternalApi", Jzon.bool), Jzon.field("nprOpenData", Jzon.nullable(jsonCodec$1)), Jzon.field("hbfUploadSpec", Jzon.nullable(jsonCodec$2)), Jzon.field("downloadSpec", Jzon.nullable(jsonCodec$4)), Jzon.field("defaultMap", Jzon.nullable(Document$LumiStore.Key.jsonCodec())), Jzon.field("maps", Jzon.array(Document$LumiStore.Key.jsonCodec())));

var Facility = {
  jsonCodec: jsonCodec$5
};

function documentName(param) {
  return param.name;
}

var icon = JsxRuntime.jsx(FontAwesome.Solid.make, {
      name: "industry"
    });

var Schema = Schema$LumiStore.Make({
      documentName: documentName,
      tag: "facility",
      jsonCodec: jsonCodec$5,
      icon: icon
    });

export {
  FacilityOperationalState ,
  NprOpenData ,
  HbfUploadSpec ,
  DownloadSource ,
  DownloadSpec ,
  Facility ,
  Schema ,
}
/* jsonCodec Not a pure module */
