// Generated by ReScript, PLEASE EDIT WITH CARE

import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as LumiOption from "lumi-rescript/lib/es6/src/LumiOption.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as LumiReactReducer from "lumi-rescript/lib/es6/src/LumiReactReducer.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as API$LumiAuthentication from "./API.bs.mjs";
import * as Form$LumiAuthentication from "./Form.bs.mjs";
import * as I18n$LumiAuthentication from "./I18n.bs.mjs";
import * as Modal$LumiAuthentication from "./Modal.bs.mjs";
import * as Validate$LumiAuthentication from "./Validate.bs.mjs";

function passwordResetLockFromUrl(url) {
  var match = url.hash;
  if (match !== "change-password") {
    return ;
  }
  var params = new URLSearchParams(url.search);
  return LumiOption.sequence2(V2$Linear.map([
                  "accountId",
                  "secret"
                ], (function (extra) {
                    return Caml_option.nullable_to_opt(params.get(extra));
                  })));
}

function replacePasswordResetLockInUrl() {
  var url = new URL(window.location.href);
  url.hash = "";
  var search = url.searchParams;
  search.delete("accountId");
  search.delete("secret");
  url.search = search.toString();
  window.location.replace(url.href);
}

function PasswordReset$ChooseNewPassword(props) {
  var dispatch = props.dispatch;
  var verifyPasswordTxt = props.verifyPasswordTxt;
  var newPasswordTxt = props.newPasswordTxt;
  var newPasswordValid = Validate$LumiAuthentication.newPassword(newPasswordTxt);
  var verifyPasswordValid = verifyPasswordTxt === newPasswordTxt;
  var valid = newPasswordValid && verifyPasswordValid;
  return JsxRuntime.jsxs(Modal$LumiAuthentication.Form.make, {
              title: "PhraseChangePassword",
              onSubmit: (function (param) {
                  dispatch("ConfirmChooseNewPassword");
                }),
              children: [
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(Form$LumiAuthentication.$$Error.make, {
                              error: props.lastError
                            }),
                        JsxRuntime.jsx("p", {
                              children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                    entry: "PhraseEnterNewPassword"
                                  })
                            }),
                        JsxRuntime.jsx(Form$LumiAuthentication.NewPasswordField.make, {
                              value: newPasswordTxt,
                              valid: newPasswordValid,
                              onChange: (function (pass) {
                                  dispatch({
                                        TAG: "UpdatePasswordTxt",
                                        _0: pass
                                      });
                                })
                            }),
                        JsxRuntime.jsx(Form$LumiAuthentication.VerifyPasswordField.make, {
                              value: verifyPasswordTxt,
                              valid: verifyPasswordValid,
                              onChange: (function (pass) {
                                  dispatch({
                                        TAG: "UpdateVerifyPasswordTxt",
                                        _0: pass
                                      });
                                })
                            })
                      ],
                      className: "modal-card-body"
                    }),
                JsxRuntime.jsx("footer", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx("button", {
                                          children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                                entry: {
                                                  TAG: "CommonEntry",
                                                  _0: "Close"
                                                }
                                              }),
                                          className: "button",
                                          type: "button",
                                          onClick: (function (param) {
                                              dispatch("BackToLogin");
                                            })
                                        }),
                                    className: "control"
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(Form$LumiAuthentication.Submit.make, {
                                          classList: [
                                            [
                                              "button",
                                              true
                                            ],
                                            [
                                              "is-success",
                                              true
                                            ]
                                          ],
                                          enabled: valid,
                                          label: "PhraseChangePassword"
                                        }),
                                    className: "control"
                                  })
                            ],
                            className: "field is-grouped is-grouped-right is-flex-1"
                          }),
                      className: "modal-card-foot"
                    })
              ]
            });
}

var ChooseNewPassword = {
  make: PasswordReset$ChooseNewPassword
};

function PasswordReset$PasswordChanged(props) {
  var dispatch = props.dispatch;
  return JsxRuntime.jsxs(Modal$LumiAuthentication.make, {
              title: "PhraseChangePassword",
              children: [
                JsxRuntime.jsx("section", {
                      children: JsxRuntime.jsx("p", {
                            children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                  entry: "PhrasePasswordChanged"
                                })
                          }),
                      className: "modal-card-body"
                    }),
                JsxRuntime.jsx("footer", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("p", {
                                  children: JsxRuntime.jsx("button", {
                                        children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                              entry: "PhraseToLogin"
                                            }),
                                        className: "button is-success",
                                        type: "button",
                                        onClick: (function (param) {
                                            dispatch("BackToLogin");
                                          })
                                      }),
                                  className: "control"
                                }),
                            className: "field is-grouped is-grouped-right is-flex-1"
                          }),
                      className: "modal-card-foot"
                    })
              ]
            });
}

var PasswordChanged = {
  make: PasswordReset$PasswordChanged
};

function PasswordReset(props) {
  var toParent = props.toParent;
  var secret = props.secret;
  var accountId = props.accountId;
  var match = LumiReactReducer.useReducer((function () {
          return {
                  TAG: "Init",
                  _0: {
                    lastError: undefined,
                    newPasswordTxt: "",
                    verifyPasswordTxt: "",
                    succeeded: false
                  }
                };
        }), undefined, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "ConfirmChooseNewPassword" :
                  return {
                          TAG: "Effect",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              return LumiRequest.resultFutureEffect(API$LumiAuthentication.resetPasswordConfirm(accountId, secret, param.state.newPasswordTxt), (function () {
                                            dispatch("ChooseNewPasswordSucceeded");
                                          }), (function (err) {
                                            dispatch({
                                                  TAG: "ChooseNewPasswordFailed",
                                                  _0: err
                                                });
                                          }));
                            })
                        };
              case "ChooseNewPasswordSucceeded" :
                  return {
                          TAG: "UpdateWithEffect",
                          _0: {
                            lastError: state.lastError,
                            newPasswordTxt: state.newPasswordTxt,
                            verifyPasswordTxt: state.verifyPasswordTxt,
                            succeeded: true
                          },
                          _1: (function (param) {
                              replacePasswordResetLockInUrl();
                            })
                        };
              case "BackToLogin" :
                  return {
                          TAG: "Effect",
                          _0: (function (param) {
                              toParent({
                                    TAG: "ShowPasswordLogin",
                                    initialEmail: "",
                                    initialError: undefined
                                  });
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdatePasswordTxt" :
                  return {
                          TAG: "Update",
                          _0: {
                            lastError: state.lastError,
                            newPasswordTxt: action._0,
                            verifyPasswordTxt: state.verifyPasswordTxt,
                            succeeded: state.succeeded
                          }
                        };
              case "UpdateVerifyPasswordTxt" :
                  return {
                          TAG: "Update",
                          _0: {
                            lastError: state.lastError,
                            newPasswordTxt: state.newPasswordTxt,
                            verifyPasswordTxt: action._0,
                            succeeded: state.succeeded
                          }
                        };
              case "ChooseNewPasswordFailed" :
                  var err = action._0;
                  console.error("resetPasswordConfirm failed", err);
                  if (typeof err === "object" && err.TAG === "ServerError" && err.status === 401) {
                    return {
                            TAG: "UpdateWithEffect",
                            _0: {
                              lastError: "PhraseWaitingTimeExpired",
                              newPasswordTxt: state.newPasswordTxt,
                              verifyPasswordTxt: state.verifyPasswordTxt,
                              succeeded: state.succeeded
                            },
                            _1: (function (param) {
                                toParent({
                                      TAG: "ShowPasswordForgotten",
                                      initialEmail: "",
                                      initialError: "PhraseWaitingTimeExpired"
                                    });
                              })
                          };
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            lastError: I18n$LumiAuthentication.entryFromApiError(err),
                            newPasswordTxt: state.newPasswordTxt,
                            verifyPasswordTxt: state.verifyPasswordTxt,
                            succeeded: state.succeeded
                          }
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var match$1 = match[0];
  if (match$1.succeeded) {
    return JsxRuntime.jsx(PasswordReset$PasswordChanged, {
                dispatch: dispatch
              });
  } else {
    return JsxRuntime.jsx(PasswordReset$ChooseNewPassword, {
                lastError: match$1.lastError,
                newPasswordTxt: match$1.newPasswordTxt,
                verifyPasswordTxt: match$1.verifyPasswordTxt,
                dispatch: dispatch
              });
  }
}

var T;

var make = PasswordReset;

export {
  T ,
  passwordResetLockFromUrl ,
  replacePasswordResetLockInUrl ,
  ChooseNewPassword ,
  PasswordChanged ,
  make ,
}
/* LumiRequest Not a pure module */
