// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as React from "react";
import * as Spinner from "./Spinner.bs.mjs";
import * as LumiReact from "./LumiReact.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as JsCookieJs from "lumi-rescript/src/js-cookie.js";

function toShortString(err) {
  var shorten = function (str) {
    var parts = str.split(" ");
    if (parts.length <= 3) {
      return str;
    } else {
      return parts[0] + " (...) " + parts[parts.length - 1 | 0];
    }
  };
  if (err.TAG === "FailureResponse") {
    return "HTTP " + err.status.toString() + " " + err.statusText + Core__Option.mapOr(err.body, "", (function (body) {
                  return ": " + shorten(body);
                }));
  } else {
    return "Invalid response: " + shorten(err._0);
  }
}

function toString(err) {
  if (err.TAG === "FailureResponse") {
    return "HTTP " + err.status.toString() + " " + err.statusText + Core__Option.mapOr(err.body, "", (function (body) {
                  return ": " + body;
                }));
  } else {
    return "Invalid response: " + err._0;
  }
}

function LumiFetch$Error$Notification(props) {
  var __classList = props.classList;
  var error = props.error;
  var classList = __classList !== undefined ? __classList : [[
        "is-danger",
        true
      ]];
  var className = LumiReact.classList([[
            "notification",
            true
          ]].concat(classList));
  var tmp;
  tmp = error.TAG === "FailureResponse" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("span", {
                  children: "HTTP " + error.status.toString() + " " + error.statusText
                }),
            LumiReact.renderOption(undefined, error.body, (function (body) {
                    return JsxRuntime.jsx("pre", {
                                children: body,
                                style: {
                                  whiteSpace: "pre-wrap"
                                }
                              });
                  }))
          ]
        }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("span", {
                  children: "Invalid response"
                }),
            JsxRuntime.jsx("pre", {
                  children: error._0,
                  style: {
                    whiteSpace: "pre-wrap"
                  }
                })
          ]
        });
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: className,
              onClick: props.onClick
            });
}

var $$Notification = {
  make: LumiFetch$Error$Notification
};

var $$Error = {
  toShortString: toShortString,
  toString: toString,
  $$Notification: $$Notification
};

var $$Request = {};

async function responseOk(response) {
  if (response.ok) {
    return {
            TAG: "Ok",
            _0: response
          };
  }
  var bodyString = await response.text();
  var tmp = bodyString === "" ? undefined : bodyString;
  return {
          TAG: "Error",
          _0: {
            TAG: "FailureResponse",
            status: response.status,
            statusText: response.statusText,
            body: tmp
          }
        };
}

async function decodeBlob(response) {
  var resp = await responseOk(response);
  if (resp.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: resp._0
          };
  }
  var blob = await resp._0.blob();
  return {
          TAG: "Ok",
          _0: blob
        };
}

async function decodeNull(response) {
  var resp = await responseOk(response);
  if (resp.TAG !== "Ok") {
    return {
            TAG: "Error",
            _0: resp._0
          };
  }
  var text = await resp._0.text();
  if (text === "") {
    return {
            TAG: "Ok",
            _0: undefined
          };
  } else {
    return {
            TAG: "Error",
            _0: {
              TAG: "InvalidResponse",
              _0: "Response body should be empty but is: " + text
            }
          };
  }
}

async function decodeJson(response, codec) {
  var resp = await responseOk(response);
  if (resp.TAG === "Ok") {
    return Core__Result.mapError(Core__Result.mapError(Jzon.decodeWith(await resp._0.json(), codec), Jzon.DecodingError.toString), (function (x) {
                  return {
                          TAG: "InvalidResponse",
                          _0: x
                        };
                }));
  } else {
    return {
            TAG: "Error",
            _0: resp._0
          };
  }
}

var $$Response = {
  responseOk: responseOk,
  decodeBlob: decodeBlob,
  decodeNull: decodeNull,
  decodeJson: decodeJson
};

var lumiAuth_1 = Core__Option.getOr(JsCookieJs.get("XSRF-TOKEN"), "");

var lumiAuth = [
  "X-XSRF-TOKEN",
  lumiAuth_1
];

function decodeJson$1(headers, headerKey, codec) {
  var header = headers.get(headerKey);
  return Core__Result.mapError((header == null) ? ({
                  TAG: "Error",
                  _0: "Missing " + headerKey + " header"
                }) : Core__Result.mapError(Jzon.decodeStringWith(header, codec), (function (x) {
                      return Jzon.DecodingError.toString(x);
                    })), (function (x) {
                return {
                        TAG: "InvalidResponse",
                        _0: x
                      };
              }));
}

function decodeString(headers, headerKey, decode) {
  var header = headers.get(headerKey);
  return Core__Result.mapError((header == null) ? ({
                  TAG: "Error",
                  _0: "Missing " + headerKey + " header"
                }) : decode(header), (function (x) {
                return {
                        TAG: "InvalidResponse",
                        _0: x
                      };
              }));
}

var Headers_acceptJpeg = [
  "Accept",
  "image/jpeg"
];

var Headers_acceptJson = [
  "Accept",
  "application/json;charset=utf-8"
];

var Headers_contentJson = [
  "Content-Type",
  "application/json;charset=utf-8"
];

var Headers_contentSvg = [
  "Content-Type",
  "image/svg+xml"
];

var $$Headers = {
  acceptJpeg: Headers_acceptJpeg,
  acceptJson: Headers_acceptJson,
  contentJson: Headers_contentJson,
  contentSvg: Headers_contentSvg,
  lumiAuth: lumiAuth,
  decodeJson: decodeJson$1,
  decodeString: decodeString
};

async function $$fetch$1(request) {
  var response = await fetch(request.url, request.init);
  return await request.decode(response);
}

function map(resp, f) {
  if (typeof resp !== "object") {
    return "Loading";
  }
  if (resp.TAG === "Reloading") {
    var result = resp._0;
    if (result.TAG === "Ok") {
      return {
              TAG: "Reloading",
              _0: {
                TAG: "Ok",
                _0: f(result._0)
              }
            };
    } else {
      return {
              TAG: "Reloading",
              _0: {
                TAG: "Error",
                _0: result._0
              }
            };
    }
  }
  var result$1 = resp._0;
  if (result$1.TAG === "Ok") {
    return {
            TAG: "Fetched",
            _0: {
              TAG: "Ok",
              _0: f(result$1._0)
            }
          };
  } else {
    return {
            TAG: "Reloading",
            _0: {
              TAG: "Error",
              _0: result$1._0
            }
          };
  }
}

function flatMap(resp, f) {
  if (typeof resp !== "object") {
    return "Loading";
  }
  if (resp.TAG === "Reloading") {
    var result = resp._0;
    if (result.TAG === "Ok") {
      var res = f(result._0);
      if (typeof res !== "object" || res.TAG === "Reloading") {
        return res;
      } else {
        return {
                TAG: "Reloading",
                _0: res._0
              };
      }
    } else {
      return {
              TAG: "Reloading",
              _0: {
                TAG: "Error",
                _0: result._0
              }
            };
    }
  }
  var result$1 = resp._0;
  if (result$1.TAG === "Ok") {
    return f(result$1._0);
  } else {
    return {
            TAG: "Reloading",
            _0: {
              TAG: "Error",
              _0: result$1._0
            }
          };
  }
}

function toOption(resp) {
  if (typeof resp !== "object") {
    return ;
  }
  if (resp.TAG === "Reloading") {
    var result = resp._0;
    if (result.TAG === "Ok") {
      return Caml_option.some(result._0);
    } else {
      return ;
    }
  }
  var result$1 = resp._0;
  if (result$1.TAG === "Ok") {
    return Caml_option.some(result$1._0);
  }
  
}

function splitTuple(tup) {
  if (typeof tup !== "object") {
    return [
            "Loading",
            "Loading"
          ];
  }
  if (tup.TAG === "Reloading") {
    var err = tup._0;
    if (err.TAG === "Ok") {
      var match = err._0;
      return [
              {
                TAG: "Reloading",
                _0: {
                  TAG: "Ok",
                  _0: match[0]
                }
              },
              {
                TAG: "Reloading",
                _0: {
                  TAG: "Ok",
                  _0: match[1]
                }
              }
            ];
    }
    var err$1 = err._0;
    return [
            {
              TAG: "Reloading",
              _0: {
                TAG: "Error",
                _0: err$1
              }
            },
            {
              TAG: "Reloading",
              _0: {
                TAG: "Error",
                _0: err$1
              }
            }
          ];
  }
  var err$2 = tup._0;
  if (err$2.TAG === "Ok") {
    var match$1 = err$2._0;
    return [
            {
              TAG: "Fetched",
              _0: {
                TAG: "Ok",
                _0: match$1[0]
              }
            },
            {
              TAG: "Fetched",
              _0: {
                TAG: "Ok",
                _0: match$1[1]
              }
            }
          ];
  }
  var err$3 = err$2._0;
  return [
          {
            TAG: "Fetched",
            _0: {
              TAG: "Error",
              _0: err$3
            }
          },
          {
            TAG: "Fetched",
            _0: {
              TAG: "Error",
              _0: err$3
            }
          }
        ];
}

function render(result, f) {
  if (typeof result !== "object") {
    return JsxRuntime.jsx(Spinner.make, {});
  }
  if (result.TAG === "Reloading") {
    return JsxRuntime.jsx(Spinner.make, {});
  }
  var error = result._0;
  if (error.TAG === "Ok") {
    return f(error._0);
  } else {
    return JsxRuntime.jsx(LumiFetch$Error$Notification, {
                error: error._0
              });
  }
}

function renderShowOnReload(result, f) {
  if (typeof result !== "object") {
    return JsxRuntime.jsx(Spinner.make, {});
  }
  if (result.TAG === "Reloading") {
    var error = result._0;
    if (error.TAG === "Ok") {
      return f(error._0);
    } else {
      return JsxRuntime.jsx(LumiFetch$Error$Notification, {
                  error: error._0
                });
    }
  }
  var error$1 = result._0;
  if (error$1.TAG === "Ok") {
    return f(error$1._0);
  } else {
    return JsxRuntime.jsx(LumiFetch$Error$Notification, {
                error: error$1._0
              });
  }
}

function renderNull(result, f) {
  return Core__Option.mapOr(toOption(result), null, f);
}

function isLoading(result) {
  if (typeof result !== "object" || result.TAG === "Reloading") {
    return true;
  } else {
    return false;
  }
}

function sequence2(param) {
  var b = param[1];
  return flatMap(param[0], (function (a) {
                return map(b, (function (b) {
                              return [
                                      a,
                                      b
                                    ];
                            }));
              }));
}

function sequence3(param) {
  var c = param[2];
  var b = param[1];
  return flatMap(param[0], (function (a) {
                return map(sequence2([
                                b,
                                c
                              ]), (function (param) {
                              return [
                                      a,
                                      param[0],
                                      param[1]
                                    ];
                            }));
              }));
}

function sequence4(param) {
  var d = param[3];
  var c = param[2];
  var b = param[1];
  return flatMap(param[0], (function (a) {
                return map(sequence3([
                                b,
                                c,
                                d
                              ]), (function (param) {
                              return [
                                      a,
                                      param[0],
                                      param[1],
                                      param[2]
                                    ];
                            }));
              }));
}

function sequence5(param) {
  var e = param[4];
  var d = param[3];
  var c = param[2];
  var b = param[1];
  return flatMap(param[0], (function (a) {
                return map(sequence4([
                                b,
                                c,
                                d,
                                e
                              ]), (function (param) {
                              return [
                                      a,
                                      param[0],
                                      param[1],
                                      param[2],
                                      param[3]
                                    ];
                            }));
              }));
}

var Result = {
  map: map,
  flatMap: flatMap,
  toOption: toOption,
  splitTuple: splitTuple,
  render: render,
  renderShowOnReload: renderShowOnReload,
  renderNull: renderNull,
  isLoading: isLoading,
  sequence2: sequence2,
  sequence3: sequence3,
  sequence4: sequence4,
  sequence5: sequence5
};

function useFetch(f, deps, onFetchOpt) {
  var onFetch = onFetchOpt !== undefined ? onFetchOpt : (async function (param) {
        
      });
  var match = React.useState(function () {
        
      });
  var setData = match[1];
  var $$fetch = React.useCallback((async function (key) {
          setData(function (prev) {
                if (prev !== undefined && typeof prev === "object") {
                  return {
                          TAG: "Reloading",
                          _0: prev._0
                        };
                } else {
                  return "Loading";
                }
              });
          var response = await f(key);
          if (response.TAG === "Ok") {
            var response$1 = response._0;
            setData(function (param) {
                  return {
                          TAG: "Fetched",
                          _0: {
                            TAG: "Ok",
                            _0: response$1
                          }
                        };
                });
            return await onFetch(response$1);
          }
          var err = response._0;
          setData(function (param) {
                return {
                        TAG: "Fetched",
                        _0: {
                          TAG: "Error",
                          _0: err
                        }
                      };
              });
          console.error("Error fetching data", err, key);
        }), deps);
  return {
          data: match[0],
          fetch: $$fetch
        };
}

var Manual = {
  useFetch: useFetch
};

function useFetch$1(key, f, deps) {
  var match = React.useState(function () {
        return "Loading";
      });
  var setData = match[1];
  var data = match[0];
  var $$fetch = React.useCallback((async function (key) {
          setData(function (prev) {
                if (typeof prev !== "object") {
                  return "Loading";
                } else {
                  return {
                          TAG: "Reloading",
                          _0: prev._0
                        };
                }
              });
          var x = await f(key);
          if (x.TAG === "Ok") {
            var x$1 = x._0;
            return setData(function (param) {
                        return {
                                TAG: "Fetched",
                                _0: {
                                  TAG: "Ok",
                                  _0: x$1
                                }
                              };
                      });
          }
          var err = x._0;
          console.error("Error fetching data", err);
          return setData(function (param) {
                      return {
                              TAG: "Fetched",
                              _0: {
                                TAG: "Error",
                                _0: err
                              }
                            };
                    });
        }), deps);
  React.useEffect((function () {
          $$fetch(key);
          return (function () {
                    setData(function (param) {
                          return data;
                        });
                  });
        }), [
        key,
        $$fetch
      ]);
  return {
          data: data,
          fetch: $$fetch
        };
}

function useMap(param, f, deps) {
  var $$fetch = param.fetch;
  var data = param.data;
  return React.useMemo((function () {
                return {
                        data: f(data),
                        fetch: $$fetch
                      };
              }), [
              data,
              deps,
              $$fetch
            ]);
}

function useMapResult(res, f, deps) {
  return useMap(res, (function (x) {
                return map(x, f);
              }), deps);
}

export {
  $$Error ,
  $$Request ,
  $$Response ,
  $$Headers ,
  $$fetch$1 as $$fetch,
  Result ,
  Manual ,
  useFetch$1 as useFetch,
  useMap ,
  useMapResult ,
}
/* lumiAuth Not a pure module */
