// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as MapElement$LumiFacilityMap from "./MapElement.bs.mjs";

function MakeKeyClasses(Cmp) {
  var empty = Belt_Map.make(Cmp);
  var fromArray = function (__x) {
    return Belt_Map.fromArray(__x, Cmp);
  };
  var merge = function (a, b) {
    return Belt_Map.merge(a, b, (function (_key, a, b) {
                  if (a !== undefined) {
                    if (b !== undefined) {
                      return a.concat(b);
                    } else {
                      return a;
                    }
                  } else if (b !== undefined) {
                    return b;
                  } else {
                    return ;
                  }
                }));
  };
  var mergeLast = function (a, b) {
    return Belt_Map.merge(a, b, (function (_key, a, b) {
                  if (a !== undefined) {
                    if (b !== undefined) {
                      return b;
                    } else {
                      return a;
                    }
                  } else if (b !== undefined) {
                    return b;
                  } else {
                    return ;
                  }
                }));
  };
  return {
          empty: empty,
          fromArray: fromArray,
          merge: merge,
          mergeLast: mergeLast,
          Cmp: Cmp
        };
}

function MakeKeyLabels(Cmp) {
  var empty = Belt_Map.make(Cmp);
  var fromArray = function (__x) {
    return Belt_Map.fromArray(__x, Cmp);
  };
  var mergeLast = function (a, b) {
    return Belt_Map.merge(a, b, (function (_key, a, b) {
                  if (a !== undefined) {
                    if (b !== undefined) {
                      return b;
                    } else {
                      return a;
                    }
                  } else if (b !== undefined) {
                    return b;
                  } else {
                    return ;
                  }
                }));
  };
  return {
          empty: empty,
          fromArray: fromArray,
          mergeLast: mergeLast,
          Cmp: Cmp
        };
}

function MakeKeyStyles(Cmp) {
  var empty = Belt_Map.make(Cmp);
  var fromArray = function (__x) {
    return Belt_Map.fromArray(__x, Cmp);
  };
  return {
          empty: empty,
          fromArray: fromArray,
          Cmp: Cmp
        };
}

function MakeMapElementMap(Cmp) {
  var empty = Belt_Map.make(Cmp);
  var fromArray = function (__x) {
    return Belt_Map.fromArray(__x, Cmp);
  };
  var singleton = function (k, v) {
    return Belt_Map.fromArray([[
                  k,
                  v
                ]], Cmp);
  };
  var merge = function (a, b) {
    return Belt_Map.merge(a, b, (function (_key, a, b) {
                  if (a !== undefined) {
                    if (b !== undefined) {
                      return MapElement$LumiFacilityMap.merge(a, b);
                    } else {
                      return a;
                    }
                  } else if (b !== undefined) {
                    return b;
                  } else {
                    return ;
                  }
                }));
  };
  var mergeLast = function (a, b) {
    return Belt_Map.merge(a, b, (function (_key, a, b) {
                  if (a !== undefined) {
                    if (b !== undefined) {
                      return b;
                    } else {
                      return a;
                    }
                  } else if (b !== undefined) {
                    return b;
                  } else {
                    return ;
                  }
                }));
  };
  return {
          empty: empty,
          fromArray: fromArray,
          singleton: singleton,
          merge: merge,
          mergeLast: mergeLast,
          Cmp: Cmp
        };
}

export {
  MakeKeyClasses ,
  MakeKeyLabels ,
  MakeKeyStyles ,
  MakeMapElementMap ,
}
/* No side effect */
