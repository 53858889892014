// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as V2f$Linear from "lumi-linear/lib/es6/src/V2f.bs.mjs";
import * as M23f$Linear from "lumi-linear/lib/es6/src/M23f.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Transform$Linear from "lumi-linear/lib/es6/src/Transform.bs.mjs";

function toInt(button) {
  switch (button) {
    case "Left" :
        return 0;
    case "Middle" :
        return 1;
    case "Right" :
        return 2;
    
  }
}

function fromInt(n) {
  switch (n) {
    case 0 :
        return "Left";
    case 1 :
        return "Middle";
    case 2 :
        return "Right";
    default:
      return ;
  }
}

function fromEvent(e) {
  return fromInt(e.button);
}

var MouseButton = {
  toInt: toInt,
  fromInt: fromInt,
  fromEvent: fromEvent
};

function useZoom(transform, setTransform, panButton, getMousePosition) {
  var match = React.useState(function () {
        
      });
  var setMousePosition = match[1];
  var mousePosition = match[0];
  var transform$1 = Core__Option.mapOr(mousePosition, transform, (function (param) {
          return Transform$Linear.M23f.addTranslation(transform, V2f$Linear.minus(param.now, param.start));
        }));
  return {
          onKeyDown: (function (e) {
              if (e.key === "Escape") {
                return setTransform(function (param) {
                            return Transform$Linear.M23f.identity;
                          });
              }
              
            }),
          onMouseDown: (function (e) {
              if (!Caml_obj.equal(fromInt(e.button), panButton)) {
                return ;
              }
              var position = getMousePosition(e);
              setMousePosition(function (param) {
                    return {
                            start: position,
                            now: position
                          };
                  });
              e.stopPropagation();
            }),
          onMouseMove: (function (e) {
              Core__Option.forEach(mousePosition, (function (param) {
                      var start = param.start;
                      var now = getMousePosition(e);
                      setMousePosition(function (param) {
                            return {
                                    start: start,
                                    now: now
                                  };
                          });
                      e.stopPropagation();
                    }));
            }),
          onMouseUp: (function (e) {
              if (Caml_obj.equal(fromInt(e.button), panButton)) {
                setTransform(function (param) {
                      return transform$1;
                    });
                setMousePosition(function (param) {
                      
                    });
                e.stopPropagation();
                return ;
              }
              
            }),
          onMouseLeave: (function (param) {
              setTransform(function (param) {
                    return transform$1;
                  });
              setMousePosition(function (param) {
                    
                  });
            }),
          onContextMenu: (function (e) {
              e.preventDefault();
            }),
          onWheel: (function (e) {
              var deltaY = e.deltaY;
              var match = e.deltaMode;
              var delta = (function (x) {
                    return Math.pow(2, - x);
                  })(match !== 0 ? (
                      match !== 1 ? deltaY : deltaY * 0.05
                    ) : deltaY * 0.002);
              var match$1 = getMousePosition(e);
              var mouseY = match$1[1];
              var mouseX = match$1[0];
              var updateTransform = function (matrix) {
                return Transform$Linear.M23f.addTranslation(M23f$Linear.scale(matrix, delta), [
                            (1 - delta) * mouseX,
                            (1 - delta) * mouseY
                          ]);
              };
              setTransform(updateTransform);
            }),
          transform: transform$1
        };
}

export {
  MouseButton ,
  useZoom ,
}
/* react Not a pure module */
