// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateFns from "lumi-rescript/lib/es6/src/DateFns.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import Format from "date-fns/format";
import * as Locale from "date-fns/locale";
import * as Translation$LumiI18n from "lumi-i18n/lib/es6/src/Translation.bs.mjs";

var nbsp = "\xa0";

function longDate(locale, date) {
  return Format(date, DateFns.Formats.$$long, {
              locale: Caml_option.some(locale)
            });
}

function translate(locale, entry) {
  switch (locale) {
    case "DEU" :
        if (typeof entry !== "object") {
          switch (entry) {
            case "Aggregates" :
                return "Summe";
            case "AllPlaces" :
                return "Alle Parkplätze";
            case "VehicleColor" :
                return "Farbe";
            case "VehicleDescriptionAndDamages" :
                return "Eigenschaften und/oder Schäden";
            case "VehicleFrameNumber" :
                return "Rahmennummer";
            case "VehicleMake" :
                return "Marke";
            case "VehicleSubscriptionNumber" :
                return "Abonnementnummer";
            case "VehicleType" :
                return "Typ";
            case "CSV" :
                return "CSV";
            case "ChangeLanguage" :
                return "Sprache ändern";
            case "ChangePassword" :
                return "Passwort ändern";
            case "Close" :
                return "Schließen";
            case "Criteria" :
                return "Kriterien";
            case "Data" :
                return "Data";
            case "DateOfRemoval" :
                return "Datum der Entfernung";
            case "Download" :
                return "Herunterladen";
            case "EndDate" :
                return "Endtermin";
            case "Excel" :
                return "Excel";
            case "Facilities" :
                return "Garagen";
            case "Facility" :
                return "Garage";
            case "Free" :
                return "Frei";
            case "ImageUnavailable" :
                return "Das Bild ist nicht verfügbar";
            case "LogOut" :
                return "Ausloggen";
            case "LowerPlaces" :
                return "Unteren Stellplätze";
            case "NoOccupationDetailsAvailable" :
                return "Keine Bilder verfügbar";
            case "OccupationDuration" :
                return "Besetzungsdauer";
            case "Occupied" :
                return "Besetzt";
            case "ParkingDuration" :
                return "Parkdauer";
            case "PleaseTryAgainLater" :
                return "Bitte versuchen Sie es später erneut";
            case "Print" :
                return "Drucken";
            case "PrintReport" :
                return "Bericht drucken";
            case "Resolution" :
                return "Auflösung";
            case "SelectOneFacilityToDownloadStatistics" :
                return "Wählen Sie einen einzelnen Garage, um Statistiken herunterzuladen";
            case "Settings" :
                return "Einstellungen";
            case "Signature" :
                return "Unterschrift";
            case "StartDate" :
                return "Anfangsdatum";
            case "Statistics" :
                return "Statistiken";
            case "OccupiedSince" :
                return "Besetzt seit" + nbsp;
            case "FreeSince" :
                return "Frei seit" + nbsp;
            case "UpperPlaces" :
                return "Oberen Stellplätze";
            case "FreePlaces" :
                return "Freie Plätze";
            case "OccupiedPlaces" :
                return "Besetzte Plätze";
            case "CurrentOccupation" :
                return "Aktuelle Belegung";
            case "AbandonedVehicles" :
                return "Verlassene Fahrzeuge";
            case "Legend" :
                return "Legende";
            case "Loading" :
                return "Wird geladen...";
            case "Overview" :
                return "Überblick";
            case "AllImages" :
                return "Alle Bilder";
            case "SwitchFacility" :
                return "Andere Garage anzeigen...";
            case "Spot" :
                return "Stellplatz";
            case "SpotDetails" :
                return "Angaben zum Stellplatz";
            case "SelectASpotForMoreInformation" :
                return "Wählen Sie einen Ort aus, um weitere Informationen zu erhalten";
            case "FacilityInInitialTrainingPeriod" :
                return "Diese Garage befindet sich derzeit in der Einarbeitungsphase. Die Genauigkeit der Messungen wird sich mit der Zeit verbessern.";
            case "ViewingCurrentOccupation" :
                return "Sie sehen die aktuelle Belegung dieses Platzes";
            case "ViewingPreviousOccupation" :
                return "Sie sehen eine frühere Besetzung dieses Platzes";
            
          }
        } else {
          switch (entry.TAG) {
            case "DaysOrLonger" :
                var n = entry._0;
                if (n !== 1) {
                  return String(n) + " Tage oder länger";
                } else {
                  return "1 Tag oder länger";
                }
            case "HoursOrLonger" :
                var n$1 = entry._0;
                if (n$1 !== 1) {
                  return String(n$1) + " Stunden oder länger";
                } else {
                  return "1 Stunde oder länger";
                }
            case "MonthsOrLonger" :
                var n$2 = entry._0;
                if (n$2 !== 1) {
                  return String(n$2) + " Monate oder länger";
                } else {
                  return "1 Monat oder länger";
                }
            case "WeeksOrLonger" :
                var n$3 = entry._0;
                if (n$3 !== 1) {
                  return String(n$3) + " Wochen oder länger";
                } else {
                  return "1 Woche oder länger";
                }
            case "Interval" :
                var match = entry._0;
                if (match === "week") {
                  return "Woche";
                } else if (match === "day") {
                  return "Tag";
                } else if (match === "month") {
                  return "Monat";
                } else if (match === "quarterHour") {
                  return "Viertelstunde";
                } else {
                  return "Stunde";
                }
            case "Metric" :
                var match$1 = entry._0;
                if (match$1 === "occupancyPctMin") {
                  return "Belegung (%, Minimum)";
                } else if (match$1 === "occupiedMean") {
                  return "Besetzte Plätze (Mittelwert)";
                } else if (match$1 === "occupancyPctMean") {
                  return "Belegung (%, Mittelwert)";
                } else if (match$1 === "occupiedMax") {
                  return "Besetzte Plätze (Maximum)";
                } else if (match$1 === "freeMean") {
                  return "Freie Plätze (Mittelwert)";
                } else if (match$1 === "freeMin") {
                  return "Freie Plätze (Minimum)";
                } else {
                  return "Belegung (%, Maximum)";
                }
            case "MinMaxMean" :
                return "Zwischen " + entry._0.toString() + " und " + entry._1.toString() + " (Mittelwert: " + entry._2.toFixed() + ")";
            case "NoAvailableFacilitiesForAccount" :
                return "Keine Garagen für " + entry._0 + " verfügbar. Melden Sie sich als anderer Benutzer an oder wenden Sie sich an den Helpdesk.";
            case "OccupationFromBeginUntilCurrent" :
                return "Vom " + longDate(Locale.de, entry._0) + " bis jetzt (aktuelle Besetzung)";
            case "OccupationFromBeginUntilEnd" :
                var end = entry._1;
                var begin = entry._0;
                if (end !== undefined) {
                  return "Vom " + longDate(Locale.de, begin) + " bis zum " + longDate(Locale.de, Caml_option.valFromOption(end));
                } else {
                  return "Vom " + longDate(Locale.de, begin) + " bis jetzt";
                }
            
          }
        }
    case "ENG" :
        if (typeof entry !== "object") {
          switch (entry) {
            case "Aggregates" :
                return "Aggregates";
            case "AllPlaces" :
                return "All spaces";
            case "VehicleColor" :
                return "Color";
            case "VehicleDescriptionAndDamages" :
                return "Description and/or damages";
            case "VehicleFrameNumber" :
                return "Frame number";
            case "VehicleMake" :
                return "Make";
            case "VehicleSubscriptionNumber" :
                return "Subscription number";
            case "VehicleType" :
                return "Type";
            case "CSV" :
                return "CSV";
            case "ChangeLanguage" :
                return "Change Language";
            case "ChangePassword" :
                return "Change Password";
            case "Close" :
                return "Close";
            case "Criteria" :
                return "Criteria";
            case "Data" :
                return "Data";
            case "DateOfRemoval" :
                return "Date of removal";
            case "Download" :
                return "Download";
            case "EndDate" :
                return "End date";
            case "Excel" :
                return "Excel";
            case "Facilities" :
                return "Facilities";
            case "Facility" :
                return "Facility";
            case "Free" :
                return "Free";
            case "ImageUnavailable" :
                return "The image is currently unavailable";
            case "LogOut" :
                return "Log Out";
            case "LowerPlaces" :
                return "Lower spaces";
            case "NoOccupationDetailsAvailable" :
                return "No occupation images available";
            case "OccupationDuration" :
                return "Occupation duration";
            case "Occupied" :
                return "Occupied";
            case "ParkingDuration" :
                return "Parking duration";
            case "PleaseTryAgainLater" :
                return "Please try again later";
            case "Print" :
                return "Print";
            case "PrintReport" :
                return "Print report";
            case "Resolution" :
                return "Resolution";
            case "SelectOneFacilityToDownloadStatistics" :
                return "Select a single facility to download statistics";
            case "Settings" :
                return "Settings";
            case "Signature" :
                return "Signature";
            case "StartDate" :
                return "Start date";
            case "Statistics" :
                return "Statistics";
            case "OccupiedSince" :
                return "Occupied since" + nbsp;
            case "FreeSince" :
                return "Free since" + nbsp;
            case "UpperPlaces" :
                return "Upper spaces";
            case "FreePlaces" :
                return "Free places";
            case "OccupiedPlaces" :
                return "Occupied places";
            case "CurrentOccupation" :
                return "Current occupation";
            case "AbandonedVehicles" :
                return "Abandoned vehicles";
            case "Legend" :
                return "Legend";
            case "Loading" :
                return "Loading...";
            case "Overview" :
                return "Overview";
            case "AllImages" :
                return "All images";
            case "SwitchFacility" :
                return "Switch facility...";
            case "Spot" :
                return "Spot";
            case "SpotDetails" :
                return "Spot details";
            case "SelectASpotForMoreInformation" :
                return "Select a spot for more information";
            case "FacilityInInitialTrainingPeriod" :
                return "This facility is currently in the initial training period. Accuracy of the measurements will improve over time.";
            case "ViewingCurrentOccupation" :
                return "You are viewing the current occupation of this place";
            case "ViewingPreviousOccupation" :
                return "You are viewing a previous occupation of this place";
            
          }
        } else {
          switch (entry.TAG) {
            case "DaysOrLonger" :
                var n$4 = entry._0;
                if (n$4 !== 1) {
                  return String(n$4) + " days or longer";
                } else {
                  return "1 day or longer";
                }
            case "HoursOrLonger" :
                var n$5 = entry._0;
                if (n$5 !== 1) {
                  return String(n$5) + " hours or longer";
                } else {
                  return "1 hour or longer";
                }
            case "MonthsOrLonger" :
                var n$6 = entry._0;
                if (n$6 !== 1) {
                  return String(n$6) + " months or longer";
                } else {
                  return "1 month or longer";
                }
            case "WeeksOrLonger" :
                var n$7 = entry._0;
                if (n$7 !== 1) {
                  return String(n$7) + " weeks or longer";
                } else {
                  return "1 week or longer";
                }
            case "Interval" :
                var match$2 = entry._0;
                if (match$2 === "week") {
                  return "Week";
                } else if (match$2 === "day") {
                  return "Day";
                } else if (match$2 === "month") {
                  return "Month";
                } else if (match$2 === "quarterHour") {
                  return "Quarter hour";
                } else {
                  return "Hour";
                }
            case "Metric" :
                var match$3 = entry._0;
                if (match$3 === "occupancyPctMin") {
                  return "Occupancy (%, minimum)";
                } else if (match$3 === "occupiedMean") {
                  return "Occupied places (mean)";
                } else if (match$3 === "occupancyPctMean") {
                  return "Occupancy (%, mean)";
                } else if (match$3 === "occupiedMax") {
                  return "Occupied places (maximum)";
                } else if (match$3 === "freeMean") {
                  return "Free places (mean)";
                } else if (match$3 === "freeMin") {
                  return "Free places (minimum)";
                } else {
                  return "Occupancy (%, maximum)";
                }
            case "MinMaxMean" :
                return "Between " + entry._0.toString() + " and " + entry._1.toString() + " (average: " + entry._2.toFixed() + ")";
            case "NoAvailableFacilitiesForAccount" :
                return "No available facilities for " + entry._0 + ". Log in as a different user or contact support.";
            case "OccupationFromBeginUntilCurrent" :
                return "From " + longDate(Locale.enGB, entry._0) + " until now (current occupation)";
            case "OccupationFromBeginUntilEnd" :
                var end$1 = entry._1;
                var begin$1 = entry._0;
                if (end$1 !== undefined) {
                  return "From " + longDate(Locale.enGB, begin$1) + " until " + longDate(Locale.enGB, Caml_option.valFromOption(end$1));
                } else {
                  return "From " + longDate(Locale.enGB, begin$1) + " until now";
                }
            
          }
        }
    case "FRA" :
        if (typeof entry !== "object") {
          switch (entry) {
            case "Aggregates" :
                return "Totaux";
            case "AllPlaces" :
                return "Toutes les places";
            case "VehicleColor" :
                return "Couleur";
            case "VehicleDescriptionAndDamages" :
                return "Caractéristiques et/ou dommages";
            case "VehicleFrameNumber" :
                return "Numéro de cadre";
            case "VehicleMake" :
                return "Marque";
            case "VehicleSubscriptionNumber" :
                return "Numéro d'abonnement";
            case "VehicleType" :
                return "Type";
            case "CSV" :
                return "CSV";
            case "ChangeLanguage" :
                return "Changer de langue";
            case "ChangePassword" :
                return "Changer le mot de passe";
            case "Close" :
                return "Fermer";
            case "Criteria" :
                return "Critères";
            case "Data" :
                return "Data";
            case "DateOfRemoval" :
                return "Date de suppression";
            case "Download" :
                return "Télécharger";
            case "EndDate" :
                return "Date de fin";
            case "Excel" :
                return "Excel";
            case "Facilities" :
                return "Parkings";
            case "Facility" :
                return "Parking";
            case "Free" :
                return "Libre";
            case "ImageUnavailable" :
                return "La photo n'est pas disponible actuellement";
            case "LogOut" :
                return "Se déconnecter";
            case "LowerPlaces" :
                return "Places basses";
            case "NoOccupationDetailsAvailable" :
                return "Pas de photos disponibles";
            case "OccupationDuration" :
                return "Durée de l'occupation";
            case "Occupied" :
                return "Occupé";
            case "ParkingDuration" :
                return "Durée de stationnement";
            case "PleaseTryAgainLater" :
                return "Veuillez réessayer plus tard";
            case "Print" :
                return "Imprimer";
            case "PrintReport" :
                return "Imprimer le rapport";
            case "Resolution" :
                return "Résolution";
            case "SelectOneFacilityToDownloadStatistics" :
                return "Sélectionnez un seul parking pour télécharger les statistiques";
            case "Settings" :
                return "Paramètres";
            case "Signature" :
                return "Signature";
            case "StartDate" :
                return "Date de début";
            case "Statistics" :
                return "Statistiques";
            case "OccupiedSince" :
                return "Occupé depuis" + nbsp;
            case "FreeSince" :
                return "Libre depuis" + nbsp;
            case "UpperPlaces" :
                return "Places hautes";
            case "FreePlaces" :
                return "Places libres";
            case "OccupiedPlaces" :
                return "Places occupées";
            case "CurrentOccupation" :
                return "Occupation actuelle";
            case "AbandonedVehicles" :
                return "Véhicules abandonnés";
            case "Legend" :
                return "Légende";
            case "Loading" :
                return "Chargement en cours...";
            case "Overview" :
                return "Aperçu";
            case "AllImages" :
                return "Toutes les photos";
            case "SwitchFacility" :
                return "Voir un autre parking";
            case "Spot" :
                return "Place";
            case "SpotDetails" :
                return "Détails sur place";
            case "SelectASpotForMoreInformation" :
                return "Sélectionnez un endroit pour afficher plus d'informations";
            case "FacilityInInitialTrainingPeriod" :
                return "Ce garage est actuellement en période de formation initiale. La précision des mesures s'améliorera avec le temps.";
            case "ViewingCurrentOccupation" :
                return "Vous visualisez l'occupation actuelle de cet emplacement";
            case "ViewingPreviousOccupation" :
                return "Vous visualisez une précédente occupation de ce lieu";
            
          }
        } else {
          switch (entry.TAG) {
            case "DaysOrLonger" :
                var n$8 = entry._0;
                if (n$8 !== 1) {
                  return String(n$8) + " jours ou plus";
                } else {
                  return "1 jour ou plus";
                }
            case "HoursOrLonger" :
                var n$9 = entry._0;
                if (n$9 !== 1) {
                  return String(n$9) + " heures ou plus";
                } else {
                  return "1 heure ou plus";
                }
            case "MonthsOrLonger" :
                var n$10 = entry._0;
                if (n$10 !== 1) {
                  return String(n$10) + " mois ou plus";
                } else {
                  return "1 mois ou plus";
                }
            case "WeeksOrLonger" :
                var n$11 = entry._0;
                if (n$11 !== 1) {
                  return String(n$11) + " semaines ou plus";
                } else {
                  return "1 semaine ou plus";
                }
            case "Interval" :
                var match$4 = entry._0;
                if (match$4 === "week") {
                  return "Semaine";
                } else if (match$4 === "day") {
                  return "Jour";
                } else if (match$4 === "month") {
                  return "Mois";
                } else if (match$4 === "quarterHour") {
                  return "Un quart d'heure";
                } else {
                  return "Heure";
                }
            case "Metric" :
                var match$5 = entry._0;
                if (match$5 === "occupancyPctMin") {
                  return "Occupation (%, minimum)";
                } else if (match$5 === "occupiedMean") {
                  return "Places occupées (moyenne)";
                } else if (match$5 === "occupancyPctMean") {
                  return "Occupation (%, moyenne)";
                } else if (match$5 === "occupiedMax") {
                  return "Places occupées (maximum)";
                } else if (match$5 === "freeMean") {
                  return "Places disponibles (moyenne)";
                } else if (match$5 === "freeMin") {
                  return "Places disponibles (minimum)";
                } else {
                  return "Occupation (%, maximum)";
                }
            case "MinMaxMean" :
                return "Entre " + entry._0.toString() + " et " + entry._1.toString() + " (moyenne: " + entry._2.toFixed() + ")";
            case "NoAvailableFacilitiesForAccount" :
                return "Aucun garage disponible pour " + entry._0 + ". Connectez-vous en tant qu'utilisateur différent ou contactez le service d'assistance.";
            case "OccupationFromBeginUntilCurrent" :
                return "Du " + longDate(Locale.fr, entry._0) + " à aujourd'hui (occupation actuelle)";
            case "OccupationFromBeginUntilEnd" :
                var end$2 = entry._1;
                var begin$2 = entry._0;
                if (end$2 !== undefined) {
                  return "Du " + longDate(Locale.fr, begin$2) + " à " + longDate(Locale.fr, Caml_option.valFromOption(end$2));
                } else {
                  return "Du " + longDate(Locale.fr, begin$2) + " a aujourd'hui";
                }
            
          }
        }
    case "NLD" :
        if (typeof entry !== "object") {
          switch (entry) {
            case "Aggregates" :
                return "Totalen";
            case "AllPlaces" :
                return "Alle plaatsen";
            case "VehicleColor" :
                return "Kleur";
            case "VehicleDescriptionAndDamages" :
                return "Kenmerken en/of schade";
            case "VehicleFrameNumber" :
                return "Framenummer";
            case "VehicleMake" :
                return "Merk";
            case "VehicleSubscriptionNumber" :
                return "Abonnementsnummer";
            case "VehicleType" :
                return "Type";
            case "CSV" :
                return "CSV";
            case "ChangeLanguage" :
                return "Taal wijzigen";
            case "ChangePassword" :
                return "Wachtwoord wijzigen";
            case "Close" :
                return "Sluiten";
            case "Criteria" :
                return "Criteria";
            case "Data" :
                return "Data";
            case "DateOfRemoval" :
                return "Datum verwijdering";
            case "Download" :
                return "Download";
            case "EndDate" :
                return "Einddatum";
            case "Excel" :
                return "Excel";
            case "Facilities" :
                return "Stallingen";
            case "Facility" :
                return "Stalling";
            case "Free" :
                return "Vri";
            case "ImageUnavailable" :
                return "De foto is momenteel niet beschikbaar";
            case "LogOut" :
                return "Uitloggen";
            case "LowerPlaces" :
                return "Onderplaatsen";
            case "NoOccupationDetailsAvailable" :
                return "Geen foto's beschikbaar";
            case "OccupationDuration" :
                return "Stallingsduur";
            case "Occupied" :
                return "Bezet";
            case "ParkingDuration" :
                return "Parkeerduur";
            case "PleaseTryAgainLater" :
                return "Probeer het a.u.b. later nog eens";
            case "Print" :
                return "Afdrukken";
            case "PrintReport" :
                return "Rapport printen";
            case "Resolution" :
                return "Resolutie";
            case "SelectOneFacilityToDownloadStatistics" :
                return "Selecteer een enkele stalling om statistieken te downloaden";
            case "Settings" :
                return "Instellingen";
            case "Signature" :
                return "Handtekening";
            case "StartDate" :
                return "Begindatum";
            case "Statistics" :
                return "Statistieken";
            case "OccupiedSince" :
                return "Bezet sinds" + nbsp;
            case "FreeSince" :
                return "Vrij sinds" + nbsp;
            case "UpperPlaces" :
                return "Bovenplaatsen";
            case "FreePlaces" :
                return "Vrije plaatsen";
            case "OccupiedPlaces" :
                return "Bezette plaatsen";
            case "CurrentOccupation" :
                return "Huidige bezetting";
            case "AbandonedVehicles" :
                return "Weesvoertuigen";
            case "Legend" :
                return "Legenda";
            case "Loading" :
                return "Laden...";
            case "Overview" :
                return "Overzicht";
            case "AllImages" :
                return "Alle afbeeldingen";
            case "SwitchFacility" :
                return "Andere stalling bekijken...";
            case "Spot" :
                return "Plek";
            case "SpotDetails" :
                return "Details plek";
            case "SelectASpotForMoreInformation" :
                return "Selecteer een plaats voor meer informatie";
            case "FacilityInInitialTrainingPeriod" :
                return "Deze stalling bevindt zich momenteel in de inleerperiode. De nauwkeurigheid van de metingen wordt beter over tijd.";
            case "ViewingCurrentOccupation" :
                return "U bekijkt de huidige bezetting van deze plek";
            case "ViewingPreviousOccupation" :
                return "U bekijkt een eerdere bezetting van deze plek";
            
          }
        } else {
          switch (entry.TAG) {
            case "DaysOrLonger" :
                var n$12 = entry._0;
                if (n$12 !== 1) {
                  return String(n$12) + " dagen of langer";
                } else {
                  return "1 dag of langer";
                }
            case "HoursOrLonger" :
                var n$13 = entry._0;
                if (n$13 !== 1) {
                  return String(n$13) + " uur of langer";
                } else {
                  return "1 uur of langer";
                }
            case "MonthsOrLonger" :
                var n$14 = entry._0;
                if (n$14 !== 1) {
                  return String(n$14) + " maanden of langer";
                } else {
                  return "1 maand of langer";
                }
            case "WeeksOrLonger" :
                var n$15 = entry._0;
                if (n$15 !== 1) {
                  return String(n$15) + " weken of langer";
                } else {
                  return "1 week of langer";
                }
            case "Interval" :
                var match$6 = entry._0;
                if (match$6 === "week") {
                  return "Week";
                } else if (match$6 === "day") {
                  return "Dag";
                } else if (match$6 === "month") {
                  return "Maand";
                } else if (match$6 === "quarterHour") {
                  return "Kwartier";
                } else {
                  return "Uur";
                }
            case "Metric" :
                var match$7 = entry._0;
                if (match$7 === "occupancyPctMin") {
                  return "Bezetting (%, minimum)";
                } else if (match$7 === "occupiedMean") {
                  return "Bezette plaatsen (gemiddeld)";
                } else if (match$7 === "occupancyPctMean") {
                  return "Bezetting (%, gemiddeld)";
                } else if (match$7 === "occupiedMax") {
                  return "Bezette plaatsen (maximum)";
                } else if (match$7 === "freeMean") {
                  return "Vrije plaatsen (gemiddeld)";
                } else if (match$7 === "freeMin") {
                  return "Vrije plaatsen (minimum)";
                } else {
                  return "Bezetting (%, maximum)";
                }
            case "MinMaxMean" :
                return "Tussen " + entry._0.toString() + " en " + entry._1.toString() + " (gemiddeld: " + entry._2.toFixed() + ")";
            case "NoAvailableFacilitiesForAccount" :
                return "Geen stallingen beschikbaar voor " + entry._0 + ". Log in als een andere gebruiker of neem contact op met de helpdesk.";
            case "OccupationFromBeginUntilCurrent" :
                return "Van " + longDate(Locale.nl, entry._0) + " tot nu (huidige bezetting)";
            case "OccupationFromBeginUntilEnd" :
                var end$3 = entry._1;
                var begin$3 = entry._0;
                if (end$3 !== undefined) {
                  return "Van " + longDate(Locale.nl, begin$3) + " tot " + longDate(Locale.nl, Caml_option.valFromOption(end$3));
                } else {
                  return "Van " + longDate(Locale.nl, begin$3) + " tot nu";
                }
            
          }
        }
    default:
      return ;
  }
}

var Provider = Translation$LumiI18n.MakeProvider({
      defaultLocale: "ENG",
      translate: translate
    });

export {
  nbsp ,
  longDate ,
  Provider ,
}
/* Provider Not a pure module */
