// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FontAwesome from "lumi-rescript/lib/es6/src/FontAwesome.bs.mjs";
import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Util$Dashboard from "./Util.bs.mjs";
import * as Panel$Dashboard from "./Panel.bs.mjs";
import * as LumiReactReducer from "lumi-rescript/lib/es6/src/LumiReactReducer.bs.mjs";
import * as Router$Dashboard from "./Router.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Sensor$LumiDocuments from "lumi-documents/lib/es6/src/Sensor.bs.mjs";
import * as SpotDetails$Dashboard from "./SpotDetails.bs.mjs";
import * as Translation$Dashboard from "./Translation.bs.mjs";
import * as Sensors$LumiFacilityMap from "lumi-facility-map/lib/es6/src/Sensors.bs.mjs";
import * as Stallingsnet$LumiCentral from "lumi-central/lib/es6/src/Stallingsnet.bs.mjs";
import * as Barrier$LumiAuthentication from "lumi-authentication/lib/es6/src/Barrier.bs.mjs";
import * as MapElement$LumiFacilityMap from "lumi-facility-map/lib/es6/src/MapElement.bs.mjs";

function calcSelectedSensorState(state, route) {
  var mbSelectedSensorKey;
  mbSelectedSensorKey = typeof route !== "object" ? undefined : route._1;
  return {
          facilityMap: state.facilityMap,
          facilityInfo: state.facilityInfo,
          sensorInfos: state.sensorInfos,
          sensorElements: state.sensorElements,
          sensorStates: state.sensorStates,
          selectedSensorState: state.sensorStates.find(function (param) {
                return Caml_obj.equal(mbSelectedSensorKey, param.sensorKey);
              })
        };
}

function calcSensorElements(state) {
  var selectedSensorKey = Core__Option.map(state.selectedSensorState, (function (param) {
          return param.sensorKey;
        }));
  return {
          facilityMap: state.facilityMap,
          facilityInfo: state.facilityInfo,
          sensorInfos: state.sensorInfos,
          sensorElements: Belt_Map.mapWithKey(state.sensorInfos, (function (sensorKey, param) {
                  return MapElement$LumiFacilityMap.make(param.name, undefined, undefined, [
                              [
                                "is-active",
                                Caml_obj.equal(selectedSensorKey, sensorKey)
                              ],
                              [
                                "is-upper-place",
                                Util$Dashboard.isUpperPlace(param.placeClass)
                              ]
                            ]);
                })),
          sensorStates: state.sensorStates,
          selectedSensorState: state.selectedSensorState
        };
}

function useFacilityMaps(propRoute, propFacilityInfos, buildRoute) {
  var toAuth = Barrier$LumiAuthentication.Dispatch.useContext();
  var reducer = LumiReactReducer.useReducerWithDependencies({
        route: propRoute,
        facilityInfos: propFacilityInfos
      }, (function (param) {
          var route = param.route;
          var tmp;
          if (typeof route !== "object") {
            tmp = undefined;
          } else {
            var facilityMapKey = route._0;
            tmp = param.facilityInfos.find(function (param) {
                  return Belt_Map.has(param.maps, facilityMapKey);
                });
          }
          return {
                  TAG: "InitWithEffect",
                  _0: {
                    facilityMap: undefined,
                    facilityInfo: tmp,
                    sensorInfos: Belt_Map.make(Sensor$LumiDocuments.Schema.CmpKey),
                    sensorElements: Sensors$LumiFacilityMap.SensorElements.empty,
                    sensorStates: [],
                    selectedSensorState: undefined
                  },
                  _1: (function (param) {
                      var dispatch = param.dispatch;
                      var match = param.dependencies;
                      var route = match.route;
                      if (typeof route === "object") {
                        return LumiRequest.resultFutureEffect(Stallingsnet$LumiCentral.getFacilityMap(route._0), (function (param) {
                                      var doc = param[0];
                                      dispatch({
                                            TAG: "FacilityMapReceived",
                                            _0: doc.key,
                                            _1: doc.rev.body.contents,
                                            _2: param[1]
                                          });
                                    }), (function (err) {
                                      console.error("getFacilityMap failed", err);
                                      Barrier$LumiAuthentication.checkServerError(err, toAuth);
                                    }));
                      }
                      Core__Option.forEach(match.facilityInfos.map(function (param) {
                                    var defaultMapKey = Document$LumiStore.contents(param.facility).defaultMap;
                                    if (defaultMapKey !== undefined) {
                                      return [Document$LumiStore.Key.coerce(defaultMapKey)];
                                    } else {
                                      return Stallingsnet$LumiCentral.FacilityInfo.sortedMaps(param.maps).map(function (prim) {
                                                  return prim[0];
                                                });
                                    }
                                  }).flat()[0], (function (facilityMapKey) {
                              Router$Dashboard.navigate(buildRoute({
                                        TAG: "SelectedFacilityMap",
                                        _0: facilityMapKey,
                                        _1: undefined
                                      }));
                            }));
                    })
                };
        }), undefined, (function (old, $$new) {
          var match = old.route;
          if (typeof match !== "object") {
            return "Reinit";
          }
          var match$1 = $$new.route;
          if (typeof match$1 !== "object" || match._0 !== match$1._0) {
            return "Reinit";
          } else {
            return {
                    TAG: "Action",
                    _0: "HandleSensorChanged"
                  };
          }
        }), undefined, (function (param) {
          var action = param.action;
          var state = param.state;
          var match = param.dependencies;
          var route = match.route;
          if (typeof action !== "object") {
            return {
                    TAG: "Update",
                    _0: calcSensorElements(calcSelectedSensorState(state, route))
                  };
          }
          switch (action.TAG) {
            case "FacilityMapReceived" :
                var facilityMapKey = action._0;
                return {
                        TAG: "Update",
                        _0: calcSensorElements({
                              facilityMap: action._1,
                              facilityInfo: match.facilityInfos.find(function (param) {
                                    return Belt_Map.has(param.maps, facilityMapKey);
                                  }),
                              sensorInfos: action._2,
                              sensorElements: state.sensorElements,
                              sensorStates: state.sensorStates,
                              selectedSensorState: state.selectedSensorState
                            })
                      };
            case "SensorStatesReceived" :
                return {
                        TAG: "Update",
                        _0: calcSensorElements(calcSelectedSensorState({
                                  facilityMap: state.facilityMap,
                                  facilityInfo: state.facilityInfo,
                                  sensorInfos: state.sensorInfos,
                                  sensorElements: state.sensorElements,
                                  sensorStates: action._0,
                                  selectedSensorState: state.selectedSensorState
                                }, route))
                      };
            case "SelectSensor" :
                var mbSensorKey = action._0;
                return {
                        TAG: "Effect",
                        _0: (function (param) {
                            if (typeof route === "object") {
                              Router$Dashboard.navigate(buildRoute({
                                        TAG: "SelectedFacilityMap",
                                        _0: route._0,
                                        _1: mbSensorKey
                                      }));
                            }
                            
                          })
                      };
            
          }
        }));
  var dispatch = reducer.dispatch;
  var route = reducer.dependencies.route;
  LumiReact.usePeriodic(5000, 60000, (function (next) {
          if (typeof route !== "object") {
            return ;
          } else {
            return LumiRequest.resultFutureEffect(Stallingsnet$LumiCentral.getFacilityMapStatus(route._0), (function (sensorStates) {
                          dispatch({
                                TAG: "SensorStatesReceived",
                                _0: sensorStates
                              });
                          next(true);
                        }), (function (err) {
                          console.error("getFacilityMapStatus failed", err);
                          Barrier$LumiAuthentication.checkServerError(err, toAuth);
                          next(false);
                        }));
          }
        }), [route]);
  return reducer;
}

function FacilityMap$Sidebar(props) {
  var __children = props.children;
  var buildRoute = props.buildRoute;
  var match = props.facilityMaps;
  var state = match.state;
  var route = match.dependencies.route;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var accountDoc = Barrier$LumiAuthentication.Account.useContext();
  var facilitiesWithMaps = props.facilityInfos.filter(function (param) {
        return Belt_Map.isEmpty(param.maps) === false;
      });
  var mbFacility = Core__Option.map(state.facilityInfo, (function (param) {
          return Document$LumiStore.contents(param.facility);
        }));
  var len = facilitiesWithMaps.length;
  var tmp;
  tmp = mbFacility !== undefined && mbFacility.operationalState === "InitialTrainingPeriod" ? JsxRuntime.jsxs("p", {
          children: [
            JsxRuntime.jsx("span", {
                  children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                        name: "wand-magic-sparkles"
                      }),
                  className: "panel-icon has-text-info"
                }),
            JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                  entry: "FacilityInInitialTrainingPeriod"
                })
          ],
          className: "panel-block"
        }) : null;
  var match$1 = state.facilityInfo;
  var tmp$1;
  if (match$1 !== undefined) {
    var maps = match$1.maps;
    tmp$1 = Belt_Map.size(maps) > 1 ? Stallingsnet$LumiCentral.FacilityInfo.sortedMaps(maps).map(function (param) {
            var facilityMapKey = param[0];
            var tmp;
            tmp = typeof route !== "object" ? false : route._0 === facilityMapKey;
            return JsxRuntime.jsxs("a", {
                        children: [
                          JsxRuntime.jsx("span", {
                                children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                      name: "map"
                                    }),
                                className: "panel-icon"
                              }),
                          param[1].name
                        ],
                        className: LumiReact.classList([
                              [
                                "panel-block",
                                true
                              ],
                              [
                                "is-active",
                                tmp
                              ]
                            ]),
                        onClick: (function (param) {
                            Router$Dashboard.navigate(buildRoute({
                                      TAG: "SelectedFacilityMap",
                                      _0: facilityMapKey,
                                      _1: undefined
                                    }));
                          })
                      }, Document$LumiStore.Key.toString(facilityMapKey));
          }) : null;
  } else {
    tmp$1 = null;
  }
  var facilityMap = state.facilityMap;
  var tmp$2;
  var exit = 0;
  if (facilityMap !== undefined) {
    var selectedSensorState = state.selectedSensorState;
    if (selectedSensorState !== undefined) {
      tmp$2 = JsxRuntime.jsx(SpotDetails$Dashboard.make, {
            facilityMap: facilityMap,
            selectedSensorState: selectedSensorState,
            toApplication: props.toApplication
          });
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$2 = JsxRuntime.jsxs("p", {
          children: [
            JsxRuntime.jsx("span", {
                  children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                        name: "hand-pointer"
                      }),
                  className: "panel-icon"
                }),
            JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                  entry: "SelectASpotForMoreInformation"
                })
          ],
          className: "panel-block"
        });
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("nav", {
                    children: [
                      JsxRuntime.jsx("p", {
                            children: mbFacility !== undefined ? mbFacility.name : JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                    entry: "Loading"
                                  }),
                            className: "panel-heading is-primary"
                          }),
                      len !== 1 ? (
                          len !== 0 ? JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx("span", {
                                          children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                name: "industry"
                                              }),
                                          className: "panel-icon"
                                        }),
                                    JsxRuntime.jsx("span", {
                                          children: JsxRuntime.jsxs("select", {
                                                children: [
                                                  JsxRuntime.jsx("option", {
                                                        children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                                              entry: "SwitchFacility"
                                                            }),
                                                        value: ""
                                                      }),
                                                  facilitiesWithMaps.map(function (param) {
                                                        var facility = param.facility;
                                                        var defaultMapKey = Document$LumiStore.contents(facility).defaultMap;
                                                        var firstMap = defaultMapKey !== undefined ? Document$LumiStore.Key.coerce(defaultMapKey) : Stallingsnet$LumiCentral.FacilityInfo.sortedMaps(param.maps)[0][0];
                                                        return JsxRuntime.jsx("option", {
                                                                    children: Document$LumiStore.contents(facility).name,
                                                                    value: Document$LumiStore.Key.toString(firstMap)
                                                                  }, Document$LumiStore.Key.toString(firstMap));
                                                      })
                                                ],
                                                value: "",
                                                onChange: (function (e) {
                                                    Core__Option.forEach(Document$LumiStore.Key.fromString(e.target.value), (function (facilityMapKey) {
                                                            Router$Dashboard.navigate(buildRoute({
                                                                      TAG: "SelectedFacilityMap",
                                                                      _0: facilityMapKey,
                                                                      _1: undefined
                                                                    }));
                                                          }));
                                                  })
                                              }),
                                          className: "select is-fullwidth"
                                        })
                                  ],
                                  className: "panel-block"
                                }) : JsxRuntime.jsxs("p", {
                                  children: [
                                    JsxRuntime.jsx("span", {
                                          children: JsxRuntime.jsx(FontAwesome.Solid.make, {
                                                name: "triangle-exclamation"
                                              }),
                                          className: "panel-icon has-text-warning"
                                        }),
                                    JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                          entry: {
                                            TAG: "NoAvailableFacilitiesForAccount",
                                            _0: Document$LumiStore.contents(accountDoc).name
                                          }
                                        })
                                  ],
                                  className: "panel-block"
                                })
                        ) : null,
                      tmp,
                      tmp$1,
                      JsxRuntime.jsx("p", {
                            children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                  entry: "SpotDetails"
                                }),
                            className: "panel-heading is-primary"
                          }),
                      tmp$2,
                      JsxRuntime.jsx("p", {
                            children: JsxRuntime.jsx(Translation$Dashboard.Provider.$$Text.make, {
                                  entry: "Legend"
                                }),
                            className: "panel-heading is-primary"
                          }),
                      children,
                      state.facilityMap !== undefined ? null : JsxRuntime.jsx(Panel$Dashboard.Loading.make, {})
                    ],
                    className: "panel scrollable"
                  }),
              className: "column is-4-tablet is-4-desktop is-4-widescreen is-3-fullhd sidebar"
            });
}

var Sidebar = {
  make: FacilityMap$Sidebar
};

var T;

export {
  T ,
  calcSelectedSensorState ,
  calcSensorElements ,
  useFacilityMaps ,
  Sidebar ,
}
/* LumiReact Not a pure module */
