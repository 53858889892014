// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";

function make(label, title, style, classListOpt) {
  var classList = classListOpt !== undefined ? classListOpt : [];
  return {
          label: label,
          title: title,
          style: style,
          classList: classList
        };
}

function merge(a, b) {
  return {
          label: Core__Option.orElse(a.label, b.label),
          title: Core__Option.orElse(a.title, b.title),
          style: Core__Option.orElse(a.style, b.style),
          classList: a.classList.concat(b.classList)
        };
}

export {
  make ,
  merge ,
}
/* No side effect */
