// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";

var fromArray = Belt_MapString.fromArray;

function merge(a, b) {
  return Belt_MapString.merge(a, b, (function (_key, a, b) {
                if (a !== undefined) {
                  if (b !== undefined) {
                    return a.concat(b);
                  } else {
                    return a;
                  }
                } else if (b !== undefined) {
                  return b;
                } else {
                  return ;
                }
              }));
}

var empty;

export {
  empty ,
  fromArray ,
  merge ,
}
/* No side effect */
