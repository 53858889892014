// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Jzon from "rescript-jzon/lib/es6/src/Jzon.bs.mjs";
import * as LumiJzon from "lumi-rescript/lib/es6/src/LumiJzon.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as V2f$Linear from "lumi-linear/lib/es6/src/V2f.bs.mjs";
import * as M22f$Linear from "lumi-linear/lib/es6/src/M22f.bs.mjs";
import * as StringCodec from "lumi-rescript/lib/es6/src/StringCodec.bs.mjs";
import * as Core__Result from "@rescript/core/lib/es6/src/Core__Result.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as BoundingBox$Linear from "lumi-linear/lib/es6/src/BoundingBox.bs.mjs";
import * as NNRoi$LumiDocuments from "./NNRoi.bs.mjs";
import * as Translation$LumiI18n from "lumi-i18n/lib/es6/src/Translation.bs.mjs";
import * as AxesModel$LumiDocuments from "./AxesModel.bs.mjs";
import * as StapleModel$LumiDocuments from "./StapleModel.bs.mjs";
import * as QuadrilateralModel$LumiDocuments from "./QuadrilateralModel.bs.mjs";

import '../../../src/css/place-model.sass'
;

function axesToQuadrilateralModel(param) {
  var baseBack = param.baseBack;
  var baseFront = param.baseFront;
  var sf = V2f$Linear.minus(baseFront, param.side);
  return {
          a: V2f$Linear.minus(baseFront, sf),
          b: V2f$Linear.plus(baseFront, sf),
          c: V2f$Linear.plus(baseBack, sf),
          d: V2f$Linear.minus(baseBack, sf),
          up: param.up,
          width: param.sideLength * 2
        };
}

var options = [
  "AxesPlaceModel",
  "QuadrilateralPlaceModel",
  "StaplePlaceModel"
];

var jsonCodec = Jzon.custom((function (value) {
        var tmp;
        switch (value) {
          case "AxesPlaceModel" :
              tmp = "axes";
              break;
          case "QuadrilateralPlaceModel" :
              tmp = "quadrilateral";
              break;
          case "StaplePlaceModel" :
              tmp = "staple";
              break;
          
        }
        return Jzon.encodeWith(tmp, Jzon.string);
      }), (function (json) {
        return Core__Result.flatMap(Jzon.decodeWith(json, Jzon.string), (function (str) {
                      switch (str) {
                        case "axes" :
                            return {
                                    TAG: "Ok",
                                    _0: "AxesPlaceModel"
                                  };
                        case "quadrilateral" :
                            return {
                                    TAG: "Ok",
                                    _0: "QuadrilateralPlaceModel"
                                  };
                        case "staple" :
                            return {
                                    TAG: "Ok",
                                    _0: "StaplePlaceModel"
                                  };
                        default:
                          return {
                                  TAG: "Error",
                                  _0: {
                                    NAME: "UnexpectedJsonValue",
                                    VAL: [
                                      [],
                                      str
                                    ]
                                  }
                                };
                      }
                    }));
      }));

var stringCodec = StringCodec.jzon(jsonCodec);

function translate(locale, entry) {
  switch (locale) {
    case "DEU" :
        switch (entry) {
          case "AxesPlaceModel" :
              return "Achsen-Platzmodell";
          case "QuadrilateralPlaceModel" :
              return "Viereck-Platzmodell";
          case "StaplePlaceModel" :
              return "Klammer-Platzmodell";
          
        }
    case "ENG" :
        switch (entry) {
          case "AxesPlaceModel" :
              return "Axes place model";
          case "QuadrilateralPlaceModel" :
              return "Quadrilateral place model";
          case "StaplePlaceModel" :
              return "Staple place model";
          
        }
    case "FRA" :
        switch (entry) {
          case "AxesPlaceModel" :
              return "Modèle de endroit a axe";
          case "QuadrilateralPlaceModel" :
              return "Modèle de endroit quadrilatère";
          case "StaplePlaceModel" :
              return "Modèle de endroit agrafe";
          
        }
    case "NLD" :
        switch (entry) {
          case "AxesPlaceModel" :
              return "Assen plaatsmodel";
          case "QuadrilateralPlaceModel" :
              return "Vierhoek plaatsmodel";
          case "StaplePlaceModel" :
              return "Nietjes plaatsmodel";
          
        }
    default:
      return ;
  }
}

var T = Translation$LumiI18n.MakeProvider({
      defaultLocale: "ENG",
      translate: translate
    });

var Enum = {
  options: options,
  jsonCodec: jsonCodec,
  stringCodec: stringCodec,
  T: T
};

function toEnum(pm) {
  switch (pm.TAG) {
    case "AxesPlaceModel" :
        return "AxesPlaceModel";
    case "QuadrilateralPlaceModel" :
        return "QuadrilateralPlaceModel";
    case "StaplePlaceModel" :
        return "StaplePlaceModel";
    
  }
}

function axesPlaceModel(am) {
  return {
          TAG: "AxesPlaceModel",
          _0: am
        };
}

function quadrilateralPlaceModel(qm) {
  return {
          TAG: "QuadrilateralPlaceModel",
          _0: qm
        };
}

function staplePlaceModel(sm) {
  return {
          TAG: "StaplePlaceModel",
          _0: sm
        };
}

var jsonCodec$1 = LumiJzon.taggedVariant((function (placeModel) {
        switch (placeModel.TAG) {
          case "AxesPlaceModel" :
              return [
                      "AxesPlaceModel",
                      Jzon.encodeWith(placeModel._0, AxesModel$LumiDocuments.jsonCodec)
                    ];
          case "QuadrilateralPlaceModel" :
              return [
                      "QuadrilateralPlaceModel",
                      Jzon.encodeWith(placeModel._0, QuadrilateralModel$LumiDocuments.jsonCodec)
                    ];
          case "StaplePlaceModel" :
              return [
                      "StaplePlaceModel",
                      Jzon.encodeWith(placeModel._0, StapleModel$LumiDocuments.jsonCodec)
                    ];
          
        }
      }), (function (param) {
        var json = param[1];
        var tag = param[0];
        switch (tag) {
          case "AxesPlaceModel" :
              return Core__Result.map(Jzon.decodeWith(json, AxesModel$LumiDocuments.jsonCodec), axesPlaceModel);
          case "QuadrilateralPlaceModel" :
              return Core__Result.map(Jzon.decodeWith(json, QuadrilateralModel$LumiDocuments.jsonCodec), quadrilateralPlaceModel);
          case "StaplePlaceModel" :
              return Core__Result.map(Jzon.decodeWith(json, StapleModel$LumiDocuments.jsonCodec), staplePlaceModel);
          default:
            return {
                    TAG: "Error",
                    _0: {
                      NAME: "UnexpectedJsonValue",
                      VAL: [
                        [],
                        tag
                      ]
                    }
                  };
        }
      }));

function up(pm, placeClass) {
  switch (pm.TAG) {
    case "AxesPlaceModel" :
        return AxesModel$LumiDocuments.up(pm._0, placeClass);
    case "QuadrilateralPlaceModel" :
        return pm._0.up;
    case "StaplePlaceModel" :
        return AxesModel$LumiDocuments.up(pm._0.axes, placeClass);
    
  }
}

function transform(placeModelSpaceSize, hmirror, vflip, placeModel) {
  switch (placeModel.TAG) {
    case "AxesPlaceModel" :
        return {
                TAG: "AxesPlaceModel",
                _0: AxesModel$LumiDocuments.transform(placeModelSpaceSize, hmirror, vflip, placeModel._0)
              };
    case "QuadrilateralPlaceModel" :
        return {
                TAG: "QuadrilateralPlaceModel",
                _0: QuadrilateralModel$LumiDocuments.transform(placeModelSpaceSize, hmirror, vflip, placeModel._0)
              };
    case "StaplePlaceModel" :
        return {
                TAG: "StaplePlaceModel",
                _0: StapleModel$LumiDocuments.transform(placeModelSpaceSize, hmirror, vflip, placeModel._0)
              };
    
  }
}

function warpAffine(placeModel, m23) {
  switch (placeModel.TAG) {
    case "AxesPlaceModel" :
        return {
                TAG: "AxesPlaceModel",
                _0: AxesModel$LumiDocuments.warpAffine(placeModel._0, m23)
              };
    case "QuadrilateralPlaceModel" :
        return {
                TAG: "QuadrilateralPlaceModel",
                _0: QuadrilateralModel$LumiDocuments.warpAffine(placeModel._0, m23)
              };
    case "StaplePlaceModel" :
        return {
                TAG: "StaplePlaceModel",
                _0: StapleModel$LumiDocuments.warpAffine(placeModel._0, m23)
              };
    
  }
}

function toPoly(placeModel, placeClass) {
  switch (placeModel.TAG) {
    case "AxesPlaceModel" :
        return AxesModel$LumiDocuments.toPoly(placeModel._0, placeClass);
    case "QuadrilateralPlaceModel" :
        return QuadrilateralModel$LumiDocuments.toPoly(placeModel._0, placeClass);
    case "StaplePlaceModel" :
        return StapleModel$LumiDocuments.toPoly(placeModel._0, placeClass);
    
  }
}

function extraPoints(placeModel) {
  switch (placeModel.TAG) {
    case "AxesPlaceModel" :
        return AxesModel$LumiDocuments.extraPoints(placeModel._0);
    case "QuadrilateralPlaceModel" :
        return QuadrilateralModel$LumiDocuments.extraPoints(placeModel._0);
    case "StaplePlaceModel" :
        return StapleModel$LumiDocuments.extraPoints(placeModel._0);
    
  }
}

function boundingBox(placeModel, placeClass) {
  return BoundingBox$Linear.axisAlignedBoundingBox(NNRoi$LumiDocuments.NNPolygon.points(toPoly(placeModel, placeClass)).concat(extraPoints(placeModel)));
}

function mainPoint(placeModel) {
  switch (placeModel.TAG) {
    case "AxesPlaceModel" :
        return AxesModel$LumiDocuments.mainPoint(placeModel._0);
    case "QuadrilateralPlaceModel" :
        return QuadrilateralModel$LumiDocuments.mainPoint(placeModel._0);
    case "StaplePlaceModel" :
        return StapleModel$LumiDocuments.mainPoint(placeModel._0);
    
  }
}

var $$default = [
  800,
  600
];

var include = LumiReact.Context.Make({
      $$default: $$default
    });

var useContext = include.useContext;

var SpaceSize_context = include.context;

var SpaceSize_Provider = include.Provider;

var SpaceSize = {
  $$default: $$default,
  context: SpaceSize_context,
  useContext: useContext,
  Provider: SpaceSize_Provider
};

function PlaceModel$Svg$Circle(props) {
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var placeModelSpaceSize = useContext();
  var className = LumiReact.classList([[
            "place-model-circle",
            true
          ]].concat(classList));
  var match = V2$Linear.map(props.mainPoint, (function (prim) {
          return prim.toString();
        }));
  var r = (function (x) {
        return (x / 100).toString();
      })(V2f$Linear.length(placeModelSpaceSize));
  return JsxRuntime.jsx("circle", {
              className: className,
              cx: match[0],
              cy: match[1],
              r: r,
              vectorEffect: "non-scaling-stroke"
            });
}

var Circle = {
  make: PlaceModel$Svg$Circle
};

function PlaceModel$Svg$Arc(props) {
  var __classList = props.classList;
  var fraction = props.fraction;
  var mainPoint = props.mainPoint;
  var classList = __classList !== undefined ? __classList : [];
  var placeModelSpaceSize = useContext();
  var radius = (function (x) {
        return x / 100;
      })(V2f$Linear.length(placeModelSpaceSize));
  var r = radius.toString();
  var match = V2$Linear.map(mainPoint, (function (prim) {
          return prim.toString();
        }));
  var cy = match[1];
  var cx = match[0];
  var className = LumiReact.classList([[
            "place-model-arc",
            true
          ]].concat(classList));
  var tmp;
  if (fraction > 0.99) {
    tmp = JsxRuntime.jsx("circle", {
          className: "place-model-arc-circle",
          cx: cx,
          cy: cy,
          r: r,
          vectorEffect: "non-scaling-stroke"
        });
  } else {
    var arcRefPoint_1 = -1 * radius;
    var arcRefPoint = [
      0,
      arcRefPoint_1
    ];
    var angle = Math.PI * 2 * fraction;
    var start = V2f$Linear.plus(mainPoint, arcRefPoint);
    var end = V2f$Linear.plus(mainPoint, M22f$Linear.Mult.v2f(M22f$Linear.Transformation.rotation(angle), arcRefPoint));
    var match$1 = V2$Linear.map(start, (function (prim) {
            return prim.toString();
          }));
    var startY = match$1[1];
    var startX = match$1[0];
    var largeArc = fraction >= 0.5 ? "1" : "0";
    var sweep = "1";
    var match$2 = V2$Linear.map(end, (function (prim) {
            return prim.toString();
          }));
    var endY = match$2[1];
    var endX = match$2[0];
    var d = "M " + cx + " " + cy + " L " + startX + " " + startY + " A " + r + " " + r + " 0 " + largeArc + " " + sweep + " " + endX + " " + endY + " Z";
    var d$1 = "M " + startX + " " + startY + " A " + r + " " + r + " 0 " + largeArc + " " + sweep + " " + endX + " " + endY;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("path", {
                  className: "place-model-arc-fill",
                  d: d,
                  vectorEffect: "non-scaling-stroke"
                }),
            JsxRuntime.jsx("path", {
                  className: "place-model-arc-edge",
                  d: d$1,
                  vectorEffect: "non-scaling-stroke"
                })
          ]
        });
  }
  return JsxRuntime.jsx("g", {
              children: tmp,
              className: className
            });
}

var Arc = {
  make: PlaceModel$Svg$Arc
};

function PlaceModel$Svg$Label(props) {
  var __classList = props.classList;
  var classList = __classList !== undefined ? __classList : [];
  var placeModelSpaceSize = useContext();
  var className = LumiReact.classList([
          [
            "place-model-label",
            true
          ],
          [
            "is-family-monospace",
            true
          ],
          [
            "is-unselectable",
            true
          ]
        ].concat(classList));
  var match = V2$Linear.map(props.mainPoint, (function (prim) {
          return prim.toString();
        }));
  var fontSize = (function (x) {
        return (x / 150 | 0).toString();
      })(V2f$Linear.length(placeModelSpaceSize));
  return JsxRuntime.jsx("text", {
              children: props.label,
              className: className,
              dominantBaseline: "central",
              fontSize: fontSize,
              textAnchor: "middle",
              x: match[0],
              y: match[1]
            });
}

var Label = {
  make: PlaceModel$Svg$Label
};

function PlaceModel$Svg$Base(props) {
  var __classList = props.classList;
  var placeModel = props.placeModel;
  var classList = __classList !== undefined ? __classList : [];
  var classList$1 = [[
        "place-model",
        true
      ]].concat(classList);
  switch (placeModel.TAG) {
    case "AxesPlaceModel" :
        var match = placeModel._0;
        return JsxRuntime.jsx(AxesModel$LumiDocuments.Svg.Base.make, {
                    baseFront: match.baseFront,
                    baseBack: match.baseBack,
                    classList: classList$1
                  });
    case "QuadrilateralPlaceModel" :
        var match$1 = placeModel._0;
        return JsxRuntime.jsx(QuadrilateralModel$LumiDocuments.Svg.Contour.make, {
                    points: [
                      match$1.a,
                      match$1.b,
                      match$1.c,
                      match$1.d
                    ],
                    classList: classList$1
                  });
    case "StaplePlaceModel" :
        var match$2 = StapleModel$LumiDocuments.toAxesModel(placeModel._0);
        return JsxRuntime.jsx(AxesModel$LumiDocuments.Svg.Base.make, {
                    baseFront: match$2.baseFront,
                    baseBack: match$2.baseBack,
                    classList: classList$1
                  });
    
  }
}

var Base = {
  make: PlaceModel$Svg$Base
};

function PlaceModel$Svg(props) {
  var __labelClassList = props.labelClassList;
  var __arcClassList = props.arcClassList;
  var __circleClassList = props.circleClassList;
  var __upClassList = props.upClassList;
  var __sideClassList = props.sideClassList;
  var __baseClassList = props.baseClassList;
  var __classList = props.classList;
  var placeModel = props.placeModel;
  var classList = __classList !== undefined ? __classList : [];
  var baseClassList = __baseClassList !== undefined ? __baseClassList : [];
  var sideClassList = __sideClassList !== undefined ? __sideClassList : [];
  var upClassList = __upClassList !== undefined ? __upClassList : [];
  var circleClassList = __circleClassList !== undefined ? __circleClassList : [];
  var arcClassList = __arcClassList !== undefined ? __arcClassList : [];
  var labelClassList = __labelClassList !== undefined ? __labelClassList : [];
  var classList$1 = [[
        "place-model",
        true
      ]].concat(classList);
  var mainPoint$1 = mainPoint(placeModel);
  var tmp;
  switch (placeModel.TAG) {
    case "AxesPlaceModel" :
        tmp = JsxRuntime.jsx(AxesModel$LumiDocuments.Svg.make, {
              axesModel: placeModel._0,
              baseClassList: baseClassList,
              sideClassList: sideClassList,
              upClassList: upClassList
            });
        break;
    case "QuadrilateralPlaceModel" :
        tmp = JsxRuntime.jsx(QuadrilateralModel$LumiDocuments.Svg.make, {
              quadModel: placeModel._0,
              baseClassList: baseClassList,
              sideClassList: sideClassList,
              upClassList: upClassList
            });
        break;
    case "StaplePlaceModel" :
        tmp = JsxRuntime.jsx(StapleModel$LumiDocuments.Svg.make, {
              stapleModel: placeModel._0,
              baseClassList: baseClassList,
              sideClassList: sideClassList,
              upClassList: upClassList
            });
        break;
    
  }
  return JsxRuntime.jsxs("g", {
              children: [
                tmp,
                JsxRuntime.jsx(PlaceModel$Svg$Circle, {
                      mainPoint: mainPoint$1,
                      classList: circleClassList
                    }),
                LumiReact.renderOption(undefined, props.arcFraction, (function (fraction) {
                        return JsxRuntime.jsx(PlaceModel$Svg$Arc, {
                                    mainPoint: mainPoint$1,
                                    fraction: fraction,
                                    classList: arcClassList
                                  });
                      })),
                LumiReact.renderOption(undefined, props.label, (function (label) {
                        return JsxRuntime.jsx(PlaceModel$Svg$Label, {
                                    mainPoint: mainPoint$1,
                                    classList: labelClassList,
                                    label: label
                                  });
                      }))
              ],
              className: LumiReact.classList(classList$1),
              onClick: props.onClick
            });
}

var Svg = {
  SpaceSize: SpaceSize,
  Circle: Circle,
  Arc: Arc,
  Label: Label,
  Base: Base,
  make: PlaceModel$Svg
};

export {
  axesToQuadrilateralModel ,
  Enum ,
  toEnum ,
  axesPlaceModel ,
  quadrilateralPlaceModel ,
  staplePlaceModel ,
  jsonCodec$1 as jsonCodec,
  up ,
  transform ,
  warpAffine ,
  toPoly ,
  extraPoints ,
  boundingBox ,
  mainPoint ,
  Svg ,
}
/*  Not a pure module */
