// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as LumiSVG from "lumi-rescript/lib/es6/src/LumiSVG.bs.mjs";
import * as LumiZoom from "lumi-rescript/lib/es6/src/LumiZoom.bs.mjs";
import * as LumiArray from "lumi-rescript/lib/es6/src/LumiArray.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Rectf$Linear from "lumi-linear/lib/es6/src/Rectf.bs.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as Core__Nullable from "@rescript/core/lib/es6/src/Core__Nullable.bs.mjs";
import * as Transform$Linear from "lumi-linear/lib/es6/src/Transform.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as BoundingBox$Linear from "lumi-linear/lib/es6/src/BoundingBox.bs.mjs";
import * as Scale$LumiFacilityMap from "./Scale.bs.mjs";
import * as Prelude$LumiFacilityMap from "./Prelude.bs.mjs";
import * as FacilityMap$LumiDocuments from "lumi-documents/lib/es6/src/FacilityMap.bs.mjs";

function Container$WithSvg(props) {
  var onSvgElement = props.onSvgElement;
  var onMouseUp = props.onMouseUp;
  var onMouseMove = props.onMouseMove;
  var onMouseDown = props.onMouseDown;
  var onDeselectAll = props.onDeselectAll;
  var __transformState = props.transformState;
  var __panButton = props.panButton;
  var __children = props.children;
  var match = props.facilityMap;
  var background = match.background;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var panButton = __panButton !== undefined ? __panButton : "Right";
  var transformState = __transformState !== undefined ? __transformState : [
      Transform$Linear.M23f.identity,
      (function (param) {
          
        })
    ];
  var match$1 = Rectf$Linear.points(FacilityMap$LumiDocuments.Shape.aabb(background));
  var displayPositions = Belt_MapString.valuesToArray(match.displays).map(function (x) {
        return x.position;
      });
  var cameraPositions = Belt_MapString.valuesToArray(match.cameras).map(function (x) {
        return x.position;
      });
  var aabb = Rectf$Linear.scaleCenter(BoundingBox$Linear.axisAlignedBoundingBox(LumiArray.concat([
                [
                  match$1[0],
                  match$1[1],
                  match$1[2],
                  match$1[3]
                ],
                displayPositions,
                cameraPositions
              ])), 1.05);
  var scale = Core__Option.getOr(Core__Option.map(Belt_MapString.valuesToArray(match.spots)[0], (function (param) {
              return Scale$LumiFacilityMap.fromShape(param.shape);
            })), Scale$LumiFacilityMap.default);
  var svgRef = React.useRef(null);
  var getMousePosition = function (e) {
    return LumiSVG.mouseEventToViewportCoordinates(e, Core__Nullable.getExn(svgRef.current));
  };
  var zoom = LumiZoom.useZoom(transformState[0], transformState[1], panButton, getMousePosition);
  var onClick = onDeselectAll !== undefined ? (function (param) {
        onDeselectAll();
      }) : undefined;
  return JsxRuntime.jsx(Scale$LumiFacilityMap.Provider.make, {
              value: scale,
              children: JsxRuntime.jsx("svg", {
                    children: JsxRuntime.jsxs("g", {
                          children: [
                            LumiReact.renderOption(undefined, props.svgDefs, (function (defs) {
                                    return JsxRuntime.jsx("defs", {
                                                children: defs
                                              });
                                  })),
                            JsxRuntime.jsxs("g", {
                                  children: [
                                    JsxRuntime.jsx(FacilityMap$LumiDocuments.Shape.make, {
                                          shape: background,
                                          classList: [[
                                              "facility-map-background",
                                              true
                                            ]],
                                          onClick: onClick
                                        }),
                                    children
                                  ],
                                  className: "zoomable"
                                })
                          ],
                          transform: LumiSVG.Transform.Matrix.fromM23f(zoom.transform)
                        }),
                    ref: Caml_option.some(function (elem) {
                          svgRef.current = elem;
                          Core__Option.forEach(onSvgElement, (function (onSvg) {
                                  onSvg(elem);
                                }));
                        }),
                    className: "facility-map-svg",
                    onKeyDown: zoom.onKeyDown,
                    onContextMenu: zoom.onContextMenu,
                    onMouseDown: (function (e) {
                        zoom.onMouseDown(e);
                        Core__Option.forEach(onMouseDown, (function (onMouseDown) {
                                onMouseDown(e);
                              }));
                      }),
                    onMouseLeave: zoom.onMouseLeave,
                    onMouseMove: (function (e) {
                        zoom.onMouseMove(e);
                        Core__Option.forEach(onMouseMove, (function (onMouseMove) {
                                onMouseMove(e);
                              }));
                      }),
                    onMouseUp: (function (e) {
                        zoom.onMouseUp(e);
                        Core__Option.forEach(onMouseUp, (function (onMouseUp) {
                                onMouseUp(e);
                              }));
                      }),
                    onWheel: zoom.onWheel,
                    preserveAspectRatio: "xMidYMid meet",
                    viewBox: LumiSVG.ViewBox.Float.fromRect(aabb)
                  })
            });
}

var WithSvg = {
  make: Container$WithSvg
};

function Container(props) {
  var __children = props.children;
  var __panButton = props.panButton;
  var panButton = __panButton !== undefined ? __panButton : "Right";
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var transformState = React.useState(function () {
        return Transform$Linear.M23f.identity;
      });
  return JsxRuntime.jsx(Container$WithSvg, {
              facilityMap: props.facilityMap,
              children: Caml_option.some(children),
              panButton: panButton,
              transformState: transformState,
              onDeselectAll: props.onDeselectAll,
              onMouseDown: props.onMouseDown,
              onMouseMove: props.onMouseMove,
              onMouseUp: props.onMouseUp,
              svgDefs: props.svgDefs
            });
}

var FacilityMap = Prelude$LumiFacilityMap.FacilityMap;

var LumiExt = Prelude$LumiFacilityMap.LumiExt;

var Camera = Prelude$LumiFacilityMap.Camera;

var Sensor = Prelude$LumiFacilityMap.Sensor;

var Indicator = Prelude$LumiFacilityMap.Indicator;

var Display = Prelude$LumiFacilityMap.Display;

var DisplayState = Prelude$LumiFacilityMap.DisplayState;

var Shape = Prelude$LumiFacilityMap.Shape;

var make = Container;

export {
  FacilityMap ,
  LumiExt ,
  Camera ,
  Sensor ,
  Indicator ,
  Display ,
  DisplayState ,
  Shape ,
  WithSvg ,
  make ,
}
/* react Not a pure module */
