// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as LumiSort from "./LumiSort.bs.mjs";
import * as Core__List from "@rescript/core/lib/es6/src/Core__List.bs.mjs";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";

function groupSortedBy(items, f) {
  return Core__List.toArray(Core__List.mapReverse(Core__Array.reduce(items, /* [] */0, (function (grouped, item) {
                        var group = f(item);
                        if (grouped) {
                          var match = grouped.hd;
                          if (Caml_obj.equal(group, match[0])) {
                            return {
                                    hd: [
                                      group,
                                      {
                                        hd: item,
                                        tl: match[1]
                                      }
                                    ],
                                    tl: grouped.tl
                                  };
                          }
                          
                        }
                        return {
                                hd: [
                                  group,
                                  {
                                    hd: item,
                                    tl: /* [] */0
                                  }
                                ],
                                tl: grouped
                              };
                      })), (function (param) {
                    return [
                            param[0],
                            Core__List.toArray(Core__List.reverse(param[1]))
                          ];
                  })));
}

function groupBy(items, f) {
  return groupSortedBy(LumiSort.stableSortOn(items.map(function (item) {
                        return [
                                f(item),
                                item
                              ];
                      }), (function (prim) {
                      return prim[0];
                    })), (function (prim) {
                  return prim[0];
                })).map(function (param) {
              return [
                      param[0],
                      param[1].map(function (prim) {
                            return prim[1];
                          })
                    ];
            });
}

function groupIntoDict(items, f) {
  var ret = {};
  items.forEach(function (item) {
        var key = f(item);
        var array = ret[key];
        if (array !== undefined) {
          array.push(item);
        } else {
          ret[key] = [item];
        }
      });
  return ret;
}

function expect1(items) {
  if (items.length !== 1) {
    return {
            TAG: "Error",
            _0: "Expected array of length 1 but got array of length " + items.length.toString()
          };
  }
  var item = items[0];
  return {
          TAG: "Ok",
          _0: item
        };
}

function reduce1(xs, f) {
  return Core__Array.reduce(xs.slice(1), Core__Option.getExn(xs[0]), f);
}

function mutate(xs, ix, f) {
  Core__Option.forEach(xs[ix], (function (x) {
          xs[ix] = f(x);
        }));
}

function getMany(xs, ixs) {
  var ys = Core__Array.make(ixs.length, undefined);
  ixs.forEach(function (inputIx, outputIx) {
        ys[outputIx] = xs[inputIx];
      });
  return Core__Array.keepSome(ys);
}

function concat(xss) {
  return Caml_splice_call.spliceObjApply([], "concat", [xss]);
}

function has(xs, x) {
  return Core__Option.isSome(Core__Array.indexOfOpt(xs, x));
}

function removeAll(xs, x) {
  return xs.filter(function (e) {
              return Caml_obj.notequal(e, x);
            });
}

export {
  groupSortedBy ,
  groupBy ,
  groupIntoDict ,
  expect1 ,
  reduce1 ,
  mutate ,
  getMany ,
  concat ,
  has ,
  removeAll ,
}
/* No side effect */
