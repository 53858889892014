// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LumiSVG from "lumi-rescript/lib/es6/src/LumiSVG.bs.mjs";
import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as V2$Linear from "lumi-linear/lib/es6/src/V2.bs.mjs";
import * as LumiString from "lumi-rescript/lib/es6/src/LumiString.bs.mjs";
import * as Core__Array from "@rescript/core/lib/es6/src/Core__Array.bs.mjs";
import * as M23f$Linear from "lumi-linear/lib/es6/src/M23f.bs.mjs";
import * as Rectf$Linear from "lumi-linear/lib/es6/src/Rectf.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as BoundingBox$Linear from "lumi-linear/lib/es6/src/BoundingBox.bs.mjs";
import * as Scale$LumiFacilityMap from "./Scale.bs.mjs";
import * as Prelude$LumiFacilityMap from "./Prelude.bs.mjs";
import * as FacilityMap$LumiDocuments from "lumi-documents/lib/es6/src/FacilityMap.bs.mjs";

function Spot(props) {
  var mapElement = props.mapElement;
  var scale = Scale$LumiFacilityMap.useContext();
  var match = FacilityMap$LumiDocuments.Shape.decompose([], props.shape);
  var baseShape = match[1];
  var transforms = match[0];
  var style = mapElement.style;
  var className = LumiReact.classList([[
            "facility-map-spot",
            true
          ]].concat(mapElement.classList));
  var transform = LumiString.nonEmpty(LumiSVG.Transform.list(transforms.map(LumiSVG.Transform.Matrix.fromM23f)));
  var tmp;
  switch (baseShape.TAG) {
    case "Rect" :
        var match$1 = V2$Linear.map(baseShape._0, (function (prim) {
                return prim.toString();
              }));
        tmp = JsxRuntime.jsx("rect", {
              style: style,
              height: match$1[1],
              width: match$1[0],
              transform: transform,
              vectorEffect: "non-scaling-stroke"
            });
        break;
    case "Polygon" :
        var points = baseShape._0.map(function (point) {
                var match = V2$Linear.map(point, (function (prim) {
                        return prim.toString();
                      }));
                return match[0] + "," + match[1];
              }).join(" ");
        tmp = JsxRuntime.jsx("polygon", {
              style: style,
              points: points,
              transform: transform,
              vectorEffect: "non-scaling-stroke"
            });
        break;
    case "Transformation" :
        throw {
              RE_EXN_ID: "Assert_failure",
              _1: [
                "Spot.res",
                31,
                32
              ],
              Error: new Error()
            };
    
  }
  return JsxRuntime.jsxs("g", {
              children: [
                tmp,
                LumiReact.renderOption(undefined, mapElement.label, (function (label) {
                        var tmp;
                        switch (baseShape.TAG) {
                          case "Rect" :
                              var match = baseShape._0;
                              var height = match[1];
                              var width = match[0];
                              tmp = [
                                [
                                  0,
                                  0
                                ],
                                [
                                  width,
                                  0
                                ],
                                [
                                  width,
                                  height
                                ],
                                [
                                  0,
                                  height
                                ]
                              ];
                              break;
                          case "Polygon" :
                              tmp = baseShape._0;
                              break;
                          case "Transformation" :
                              throw {
                                    RE_EXN_ID: "Assert_failure",
                                    _1: [
                                      "Spot.res",
                                      39,
                                      34
                                    ],
                                    Error: new Error()
                                  };
                          
                        }
                        var shapeCenter = Rectf$Linear.center(BoundingBox$Linear.axisAlignedBoundingBox(tmp));
                        var center = Core__Array.reduce(transforms, shapeCenter, (function (vec, mat) {
                                return M23f$Linear.Mult.v2f(mat, vec);
                              }));
                        var match$1 = V2$Linear.map(center, (function (prim) {
                                return prim.toString();
                              }));
                        var fontSize = (scale * 0.3).toString() + "px";
                        return JsxRuntime.jsx("text", {
                                    children: label,
                                    className: "facility-map-spot-label is-unselectable",
                                    dominantBaseline: "central",
                                    fontSize: fontSize,
                                    textAnchor: "middle",
                                    x: match$1[0],
                                    y: match$1[1]
                                  });
                      })),
                LumiReact.renderOption(undefined, mapElement.title, (function (title) {
                        return JsxRuntime.jsx("title", {
                                    children: title
                                  });
                      }))
              ],
              className: className,
              id: props.svgId,
              onClick: props.onClick
            });
}

var FacilityMap = Prelude$LumiFacilityMap.FacilityMap;

var LumiExt = Prelude$LumiFacilityMap.LumiExt;

var Camera = Prelude$LumiFacilityMap.Camera;

var Sensor = Prelude$LumiFacilityMap.Sensor;

var Indicator = Prelude$LumiFacilityMap.Indicator;

var Display = Prelude$LumiFacilityMap.Display;

var DisplayState = Prelude$LumiFacilityMap.DisplayState;

var Shape = Prelude$LumiFacilityMap.Shape;

var make = Spot;

export {
  FacilityMap ,
  LumiExt ,
  Camera ,
  Sensor ,
  Indicator ,
  Display ,
  DisplayState ,
  Shape ,
  make ,
}
/* LumiSVG Not a pure module */
