// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Sensor$LumiDocuments from "lumi-documents/lib/es6/src/Sensor.bs.mjs";
import * as Spot$LumiFacilityMap from "./Spot.bs.mjs";
import * as Prelude$LumiFacilityMap from "./Prelude.bs.mjs";
import * as Functors$LumiFacilityMap from "./Functors.bs.mjs";
import * as MapElement$LumiFacilityMap from "./MapElement.bs.mjs";
import * as SvgIdClasses$LumiFacilityMap from "./SvgIdClasses.bs.mjs";

var SensorElements = Functors$LumiFacilityMap.MakeMapElementMap(Sensor$LumiDocuments.Schema.CmpKey);

function Sensors(props) {
  var onClickSpot = props.onClickSpot;
  var __sensorElements = props.sensorElements;
  var __svgIdClasses = props.svgIdClasses;
  var svgIdClasses = __svgIdClasses !== undefined ? Caml_option.valFromOption(__svgIdClasses) : SvgIdClasses$LumiFacilityMap.empty;
  var sensorElements = __sensorElements !== undefined ? Caml_option.valFromOption(__sensorElements) : SensorElements.empty;
  return JsxRuntime.jsx("g", {
              children: Belt_MapString.toArray(props.facilityMap.spots).map(function (param) {
                    var match = param[1];
                    var mbSensorKey = match.sensor;
                    var svgId = param[0];
                    var spotElement = MapElement$LumiFacilityMap.make(undefined, undefined, undefined, [[
                              "is-clickable",
                              Core__Option.isSome(onClickSpot)
                            ]].concat(Belt_MapString.getWithDefault(svgIdClasses, svgId, [])));
                    var sensorElement = Core__Option.flatMap(mbSensorKey, (function (sensorKey) {
                            return Belt_Map.get(sensorElements, sensorKey);
                          }));
                    var mapElement = Core__Option.mapOr(sensorElement, spotElement, (function (e) {
                            return MapElement$LumiFacilityMap.merge(e, spotElement);
                          }));
                    var onClick = onClickSpot !== undefined ? (function ($$event) {
                          onClickSpot($$event, svgId, mbSensorKey);
                        }) : undefined;
                    return JsxRuntime.jsx(Spot$LumiFacilityMap.make, {
                                svgId: svgId,
                                shape: match.shape,
                                mapElement: mapElement,
                                onClick: onClick
                              }, svgId);
                  }),
              className: "facility-map-spots"
            });
}

var FacilityMap = Prelude$LumiFacilityMap.FacilityMap;

var LumiExt = Prelude$LumiFacilityMap.LumiExt;

var Camera = Prelude$LumiFacilityMap.Camera;

var Sensor = Prelude$LumiFacilityMap.Sensor;

var Indicator = Prelude$LumiFacilityMap.Indicator;

var Display = Prelude$LumiFacilityMap.Display;

var DisplayState = Prelude$LumiFacilityMap.DisplayState;

var Shape = Prelude$LumiFacilityMap.Shape;

var make = Sensors;

export {
  FacilityMap ,
  LumiExt ,
  Camera ,
  Sensor ,
  Indicator ,
  Display ,
  DisplayState ,
  Shape ,
  SensorElements ,
  make ,
}
/* SensorElements Not a pure module */
