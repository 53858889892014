// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as Locale$LumiI18n from "lumi-i18n/lib/es6/src/Locale.bs.mjs";
import * as Document$LumiStore from "lumi-store/lib/es6/src/Document.bs.mjs";
import * as Account$LumiDocuments from "lumi-documents/lib/es6/src/Account.bs.mjs";
import * as LumiExt$LumiDocuments from "lumi-documents/lib/es6/src/LumiExt.bs.mjs";
import * as Saml$LumiAuthentication from "./Saml.bs.mjs";
import * as Request$LumiAuthentication from "./Request.bs.mjs";

function cookieLoginPassword(email, password) {
  var payload = {
    email: email,
    password: password
  };
  return LumiRequest.expectJSON(LumiRequest.make("/api/auth/cookie/login/password", "POST", Caml_option.some(Object.fromEntries([[
                            "Content-Type",
                            "application/json;charset=utf-8"
                          ]])), JSON.stringify(payload), undefined), Document$LumiStore.$$Document.jsonCodec(LumiExt$LumiDocuments.jsonCodec, Account$LumiDocuments.jsonCodec));
}

function getSamlRequestData(email, destination) {
  var params = [[
        "email",
        email
      ]].concat(destination !== undefined ? [[
            "destination",
            destination
          ]] : []);
  var query = new URLSearchParams(params).toString();
  return LumiRequest.expectJSON(LumiRequest.make("/api/auth/saml/request?" + query, undefined, undefined, undefined, undefined), Saml$LumiAuthentication.RequestData.jsonCodec);
}

function cookieLoginApiKey(apiKey) {
  return LumiRequest.expectJSON(LumiRequest.make("/api/auth/cookie/login/api-key", "POST", Caml_option.some(Object.fromEntries([[
                            "Content-Type",
                            "text/plain;charset=utf-8"
                          ]])), apiKey, undefined), Document$LumiStore.$$Document.jsonCodec(LumiExt$LumiDocuments.jsonCodec, Account$LumiDocuments.jsonCodec));
}

function cookieLogout() {
  return LumiRequest.expectNoContent(LumiRequest.make("/api/auth/cookie/logout", "POST", undefined, undefined, undefined));
}

function getAccount() {
  return LumiRequest.expectJSON(Request$LumiAuthentication.make("/api/auth/account", "GET", undefined, undefined, undefined), Document$LumiStore.$$Document.jsonCodec(LumiExt$LumiDocuments.jsonCodec, Account$LumiDocuments.jsonCodec));
}

function accountChangePassword(oldPassword, newPassword) {
  var payload = {
    old: oldPassword,
    new: newPassword
  };
  return LumiRequest.expectNoContent(Request$LumiAuthentication.make("/api/auth/account/change-password", "POST", Caml_option.some(Object.fromEntries([[
                            "Content-Type",
                            "application/json;charset=utf-8"
                          ]])), JSON.stringify(payload), undefined));
}

function resetPasswordRequest(email, locale) {
  return LumiRequest.expectNoContent(LumiRequest.make("/api/auth/reset-password/request/" + Locale$LumiI18n.toString(locale), "POST", Caml_option.some(Object.fromEntries([[
                            "Content-Type",
                            "application/json;charset=utf-8"
                          ]])), JSON.stringify(email), undefined));
}

function resetPasswordConfirm(accountId, secret, password) {
  var payload = {
    accountId: accountId,
    secret: secret,
    password: password
  };
  return LumiRequest.expectNoContent(LumiRequest.make("/api/auth/reset-password/confirm", "POST", Caml_option.some(Object.fromEntries([[
                            "Content-Type",
                            "application/json;charset=utf-8"
                          ]])), JSON.stringify(payload), undefined));
}

export {
  cookieLoginPassword ,
  getSamlRequestData ,
  cookieLoginApiKey ,
  cookieLogout ,
  getAccount ,
  accountChangePassword ,
  resetPasswordRequest ,
  resetPasswordConfirm ,
}
/* LumiRequest Not a pure module */
