// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LumiReact from "lumi-rescript/lib/es6/src/LumiReact.bs.mjs";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as V2f$Linear from "lumi-linear/lib/es6/src/V2f.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";
import * as Prelude$LumiFacilityMap from "./Prelude.bs.mjs";
import * as FacilityMap$LumiDocuments from "lumi-documents/lib/es6/src/FacilityMap.bs.mjs";

var include = LumiReact.Context.Make({
      $$default: 15
    });

function fromShape(shape) {
  var points = FacilityMap$LumiDocuments.Shape.shapeVertices(shape);
  var shifted = Core__Option.getOr(Core__Option.map(points[0], (function (first) {
              return points.slice(1).concat([first]);
            })), []);
  return Caml_splice_call.spliceApply(Math.min, [Belt_Array.zipBy(points, shifted, V2f$Linear.minus).map(V2f$Linear.length)]);
}

var FacilityMap = Prelude$LumiFacilityMap.FacilityMap;

var LumiExt = Prelude$LumiFacilityMap.LumiExt;

var Camera = Prelude$LumiFacilityMap.Camera;

var Sensor = Prelude$LumiFacilityMap.Sensor;

var Indicator = Prelude$LumiFacilityMap.Indicator;

var Display = Prelude$LumiFacilityMap.Display;

var DisplayState = Prelude$LumiFacilityMap.DisplayState;

var Shape = Prelude$LumiFacilityMap.Shape;

var $$default = 15;

var context = include.context;

var useContext = include.useContext;

var Provider = include.Provider;

export {
  FacilityMap ,
  LumiExt ,
  Camera ,
  Sensor ,
  Indicator ,
  Display ,
  DisplayState ,
  Shape ,
  $$default as default,
  context ,
  useContext ,
  Provider ,
  fromShape ,
}
/* include Not a pure module */
