// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as Locale$LumiI18n from "lumi-i18n/lib/es6/src/Locale.bs.mjs";
import * as LumiReactReducer from "lumi-rescript/lib/es6/src/LumiReactReducer.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as API$LumiAuthentication from "./API.bs.mjs";
import * as Form$LumiAuthentication from "./Form.bs.mjs";
import * as I18n$LumiAuthentication from "./I18n.bs.mjs";
import * as Modal$LumiAuthentication from "./Modal.bs.mjs";
import * as Validate$LumiAuthentication from "./Validate.bs.mjs";

function PasswordForgotten$EnterEmail(props) {
  var dispatch = props.dispatch;
  var emailTxt = props.emailTxt;
  var emailValid = Validate$LumiAuthentication.email(emailTxt);
  return JsxRuntime.jsxs(Modal$LumiAuthentication.Form.make, {
              title: "PhrasePasswordForgotten",
              onSubmit: (function (param) {
                  dispatch("Confirm");
                }),
              children: [
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(Form$LumiAuthentication.$$Error.make, {
                              error: props.lastError
                            }),
                        JsxRuntime.jsx(Form$LumiAuthentication.EmailField.make, {
                              value: emailTxt,
                              valid: emailValid,
                              onChange: (function (txt) {
                                  dispatch({
                                        TAG: "UpdateEmailTxt",
                                        _0: txt
                                      });
                                })
                            })
                      ],
                      className: "modal-card-body"
                    }),
                JsxRuntime.jsx("footer", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx("button", {
                                          children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                                entry: "PhraseBackToLogin"
                                              }),
                                          className: "button",
                                          type: "button",
                                          onClick: (function (param) {
                                              dispatch("BackToLogin");
                                            })
                                        }),
                                    className: "control"
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(Form$LumiAuthentication.Submit.make, {
                                          classList: [
                                            [
                                              "button",
                                              true
                                            ],
                                            [
                                              "is-success",
                                              true
                                            ]
                                          ],
                                          enabled: emailValid,
                                          label: "PhraseSendPasswordResetMail"
                                        }),
                                    className: "control"
                                  })
                            ],
                            className: "field is-grouped is-grouped-right is-flex-1"
                          }),
                      className: "modal-card-foot"
                    })
              ]
            });
}

var EnterEmail = {
  make: PasswordForgotten$EnterEmail
};

function PasswordForgotten$LinkSent(props) {
  var dispatch = props.dispatch;
  return JsxRuntime.jsxs(Modal$LumiAuthentication.make, {
              title: "PhrasePasswordForgotten",
              children: [
                JsxRuntime.jsx("section", {
                      children: JsxRuntime.jsx("p", {
                            children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                  entry: "PhraseMailPasswordLinkSent"
                                })
                          }),
                      className: "modal-card-body"
                    }),
                JsxRuntime.jsx("footer", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("p", {
                                  children: JsxRuntime.jsx("button", {
                                        children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                              entry: "PhraseToLogin"
                                            }),
                                        className: "button is-success",
                                        type: "button",
                                        onClick: (function (param) {
                                            dispatch("BackToLogin");
                                          })
                                      }),
                                  className: "control"
                                }),
                            className: "field is-grouped is-grouped-right is-flex-1"
                          }),
                      className: "modal-card-foot"
                    })
              ]
            });
}

var LinkSent = {
  make: PasswordForgotten$LinkSent
};

function PasswordForgotten(props) {
  var toParent = props.toParent;
  var initialError = props.initialError;
  var initialEmail = props.initialEmail;
  var locale = Locale$LumiI18n.useContext();
  var match = LumiReactReducer.useReducer((function () {
          return {
                  TAG: "Init",
                  _0: {
                    emailTxt: initialEmail,
                    lastError: initialError,
                    succeeded: false
                  }
                };
        }), undefined, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "Confirm" :
                  return {
                          TAG: "Effect",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              return LumiRequest.resultFutureEffect(API$LumiAuthentication.resetPasswordRequest(param.state.emailTxt, locale), (function () {
                                            dispatch("PasswordResetSucceeded");
                                          }), (function (err) {
                                            dispatch({
                                                  TAG: "PasswordResetFailed",
                                                  _0: err
                                                });
                                          }));
                            })
                        };
              case "PasswordResetSucceeded" :
                  return {
                          TAG: "Update",
                          _0: {
                            emailTxt: state.emailTxt,
                            lastError: state.lastError,
                            succeeded: true
                          }
                        };
              case "BackToLogin" :
                  return {
                          TAG: "Effect",
                          _0: (function (param) {
                              toParent({
                                    TAG: "ShowPasswordLogin",
                                    initialEmail: param.state.emailTxt,
                                    initialError: undefined
                                  });
                            })
                        };
              
            }
          } else {
            if (action.TAG === "UpdateEmailTxt") {
              return {
                      TAG: "Update",
                      _0: {
                        emailTxt: action._0,
                        lastError: state.lastError,
                        succeeded: state.succeeded
                      }
                    };
            }
            var err = action._0;
            console.error("resetPasswordRequest failed", err);
            return {
                    TAG: "Update",
                    _0: {
                      emailTxt: state.emailTxt,
                      lastError: I18n$LumiAuthentication.entryFromApiError(err),
                      succeeded: state.succeeded
                    }
                  };
          }
        }));
  var dispatch = match[1];
  var match$1 = match[0];
  if (match$1.succeeded) {
    return JsxRuntime.jsx(PasswordForgotten$LinkSent, {
                dispatch: dispatch
              });
  } else {
    return JsxRuntime.jsx(PasswordForgotten$EnterEmail, {
                lastError: match$1.lastError,
                emailTxt: match$1.emailTxt,
                dispatch: dispatch
              });
  }
}

var T;

var make = PasswordForgotten;

export {
  T ,
  EnterEmail ,
  LinkSent ,
  make ,
}
/* LumiRequest Not a pure module */
