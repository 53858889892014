// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LumiRequest from "lumi-rescript/lib/es6/src/LumiRequest.bs.mjs";
import * as Core__Option from "@rescript/core/lib/es6/src/Core__Option.bs.mjs";
import * as LumiReactReducer from "lumi-rescript/lib/es6/src/LumiReactReducer.bs.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as API$LumiAuthentication from "./API.bs.mjs";
import * as Form$LumiAuthentication from "./Form.bs.mjs";
import * as I18n$LumiAuthentication from "./I18n.bs.mjs";
import * as Modal$LumiAuthentication from "./Modal.bs.mjs";
import * as Validate$LumiAuthentication from "./Validate.bs.mjs";

function PasswordLogin$LoginForm(props) {
  var dispatch = props.dispatch;
  var samlRequestUrl = props.samlRequestUrl;
  var passwordTxt = props.passwordTxt;
  var emailTxt = props.emailTxt;
  var emailValid = Validate$LumiAuthentication.email(emailTxt);
  var passwordValid = Validate$LumiAuthentication.existingPassword(passwordTxt);
  var samlEnabled = samlRequestUrl !== undefined;
  var submitLabel = samlEnabled ? ({
        TAG: "CommonEntry",
        _0: {
          TAG: "LogInWith",
          _0: Core__Option.getOr(props.identityProviderName, "SAML")
        }
      }) : ({
        TAG: "CommonEntry",
        _0: "LogIn"
      });
  return JsxRuntime.jsxs(Modal$LumiAuthentication.Form.make, {
              title: {
                TAG: "CommonEntry",
                _0: "LogIn"
              },
              onSubmit: (function (param) {
                  dispatch("PerformLogin");
                }),
              children: [
                JsxRuntime.jsxs("section", {
                      children: [
                        JsxRuntime.jsx(Form$LumiAuthentication.$$Error.make, {
                              error: props.lastError
                            }),
                        JsxRuntime.jsx(Form$LumiAuthentication.EmailField.make, {
                              value: emailTxt,
                              valid: emailValid,
                              onChange: (function (txt) {
                                  dispatch({
                                        TAG: "UpdateEmailTxt",
                                        _0: txt
                                      });
                                })
                            }),
                        JsxRuntime.jsx(Form$LumiAuthentication.ExistingPasswordField.make, {
                              value: passwordTxt,
                              valid: passwordValid,
                              enabled: !samlEnabled,
                              onChange: (function (txt) {
                                  dispatch({
                                        TAG: "UpdatePasswordTxt",
                                        _0: txt
                                      });
                                })
                            })
                      ],
                      className: "modal-card-body"
                    }),
                JsxRuntime.jsx("footer", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx("button", {
                                          children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                                entry: "PhrasePasswordForgotten"
                                              }),
                                          className: "button",
                                          disabled: samlEnabled,
                                          type: "button",
                                          onClick: (function (param) {
                                              dispatch("ForgotPassword");
                                            })
                                        }),
                                    className: "control"
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: samlRequestUrl !== undefined ? JsxRuntime.jsx("a", {
                                            children: JsxRuntime.jsx(I18n$LumiAuthentication.T.$$Text.make, {
                                                  entry: submitLabel
                                                }),
                                            className: "button is-success",
                                            href: samlRequestUrl
                                          }) : JsxRuntime.jsx(Form$LumiAuthentication.Submit.make, {
                                            classList: [
                                              [
                                                "button",
                                                true
                                              ],
                                              [
                                                "is-success",
                                                true
                                              ]
                                            ],
                                            enabled: emailValid && passwordValid,
                                            label: submitLabel
                                          }),
                                    className: "control"
                                  })
                            ],
                            className: "field is-grouped is-grouped-right is-flex-1"
                          }),
                      className: "modal-card-foot"
                    })
              ]
            });
}

var LoginForm = {
  make: PasswordLogin$LoginForm
};

function PasswordLogin(props) {
  var toParent = props.toParent;
  var initialError = props.initialError;
  var initialEmail = props.initialEmail;
  var match = LumiReactReducer.useReducer((function () {
          return {
                  TAG: "Init",
                  _0: {
                    emailTxt: initialEmail,
                    passwordTxt: "",
                    lastError: initialError,
                    identityProviderName: undefined,
                    samlRequestUrl: undefined
                  }
                };
        }), undefined, (function (state, action) {
          if (typeof action !== "object") {
            if (action === "PerformLogin") {
              return {
                      TAG: "Effect",
                      _0: (function (param) {
                          var dispatch = param.dispatch;
                          var match = param.state;
                          return LumiRequest.resultFutureEffect(API$LumiAuthentication.cookieLoginPassword(match.emailTxt, match.passwordTxt), (function (accountDoc) {
                                        dispatch({
                                              TAG: "LoginSucceeded",
                                              _0: accountDoc
                                            });
                                      }), (function (err) {
                                        dispatch({
                                              TAG: "LoginFailed",
                                              _0: err
                                            });
                                      }));
                        })
                    };
            } else {
              return {
                      TAG: "Effect",
                      _0: (function (param) {
                          toParent({
                                TAG: "ShowPasswordForgotten",
                                initialEmail: param.state.emailTxt,
                                initialError: undefined
                              });
                        })
                    };
            }
          }
          switch (action.TAG) {
            case "UpdateEmailTxt" :
                var updatedEmail = action._0;
                var newState_passwordTxt = state.passwordTxt;
                var newState_lastError = state.lastError;
                var newState = {
                  emailTxt: updatedEmail,
                  passwordTxt: newState_passwordTxt,
                  lastError: newState_lastError,
                  identityProviderName: undefined,
                  samlRequestUrl: undefined
                };
                if (Validate$LumiAuthentication.email(updatedEmail)) {
                  return {
                          TAG: "UpdateWithEffect",
                          _0: newState,
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              return LumiRequest.resultFutureEffect(API$LumiAuthentication.getSamlRequestData(updatedEmail, undefined), (function (samlRequestData) {
                                            dispatch({
                                                  TAG: "UpdateSamlRequestData",
                                                  _0: samlRequestData
                                                });
                                          }), (function (_err) {
                                            
                                          }));
                            })
                        };
                } else {
                  return {
                          TAG: "Update",
                          _0: newState
                        };
                }
            case "UpdatePasswordTxt" :
                return {
                        TAG: "Update",
                        _0: {
                          emailTxt: state.emailTxt,
                          passwordTxt: action._0,
                          lastError: state.lastError,
                          identityProviderName: state.identityProviderName,
                          samlRequestUrl: state.samlRequestUrl
                        }
                      };
            case "UpdateSamlRequestData" :
                var match = action._0;
                if (match.forEmailAddress === state.emailTxt) {
                  return {
                          TAG: "Update",
                          _0: {
                            emailTxt: state.emailTxt,
                            passwordTxt: state.passwordTxt,
                            lastError: state.lastError,
                            identityProviderName: match.identityProviderName,
                            samlRequestUrl: match.samlRequestUrl
                          }
                        };
                } else {
                  return {
                          TAG: "Update",
                          _0: {
                            emailTxt: state.emailTxt,
                            passwordTxt: state.passwordTxt,
                            lastError: state.lastError,
                            identityProviderName: undefined,
                            samlRequestUrl: undefined
                          }
                        };
                }
            case "LoginSucceeded" :
                var account = action._0;
                return {
                        TAG: "Effect",
                        _0: (function (param) {
                            toParent({
                                  TAG: "Authenticated",
                                  _0: account
                                });
                          })
                      };
            case "LoginFailed" :
                var error = action._0;
                console.error("Password login failed from state", state, "with error", error);
                return {
                        TAG: "Update",
                        _0: {
                          emailTxt: state.emailTxt,
                          passwordTxt: state.passwordTxt,
                          lastError: I18n$LumiAuthentication.entryFromApiError(error),
                          identityProviderName: state.identityProviderName,
                          samlRequestUrl: state.samlRequestUrl
                        }
                      };
            
          }
        }));
  var match$1 = match[0];
  return JsxRuntime.jsx(PasswordLogin$LoginForm, {
              emailTxt: match$1.emailTxt,
              passwordTxt: match$1.passwordTxt,
              lastError: match$1.lastError,
              identityProviderName: match$1.identityProviderName,
              samlRequestUrl: match$1.samlRequestUrl,
              dispatch: match[1]
            });
}

var T;

var make = PasswordLogin;

export {
  T ,
  LoginForm ,
  make ,
}
/* LumiRequest Not a pure module */
